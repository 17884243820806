import { Grid } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CustomButton } from "../../styles/theme";
import { ErrorHandler } from "../../system/ApiService";
import { NoticeApi } from "../../system/api/NoticeApi";
import { InitNotice } from "../../system/types/initObject";
import { Notice, NoticeRoutes } from "../../system/types/type";
import CrossEditor from "../Common/Editor/CrossEditor";
import TitleDiv from "../Common/TitleDiv";

interface Params {
  id: string;
}

function NoticeEdit() {
  const { id } = useParams<Params>();
  const noticeId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<Notice>(InitNotice);
  const ref = createRef<CrossEditor>();
  const [started, setStarted] = useState<boolean>(false);
  const params = {
    Width: "100%",
    Height: 800,
  };

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (noticeId) {
      NoticeApi.getNoticeDetail(noticeId)
        .then((res) => {
          setData({ ...res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      setData({ ...InitNotice, setInfo: true });
    }
  }, [noticeId]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onSave = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    let promise: Promise<any>;
    if (data.title !== "" && ref.current) {
      const editor = ref.current.editorRef;
      if (data.id !== 0) {
        promise = NoticeApi.updateNotice({
          ...data,
          content: editor.GetBodyValue() || "",
        });
      } else {
        promise = NoticeApi.createNotice({
          ...data,
          content: editor.GetBodyValue() || "",
        });
      }
      promise
        .then((res) => {
          history.push(NoticeRoutes.root);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleDiv title="공지사항 작성"></TitleDiv>
      </Grid>
      <Grid item xs={1}>
        <p className="text-center" style={{ marginTop: "10px" }}>
          제목 :
        </p>
      </Grid>
      <Grid item xs={11}>
        <input
          value={data.title}
          className="e-input"
          type="text"
          name="title"
          onChange={onChange}
          style={{ fontSize: "1rem" }}
        />
      </Grid>
      <Grid item xs={12}>
        {data.setInfo && (
          <CrossEditor
            ref={ref}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
        <br />
        <div className="text-end">
          <CustomButton onClick={onSave} iconCss="e-icons e-save">
            저장
          </CustomButton>
        </div>
      </Grid>
    </Grid>
  );
}

export default NoticeEdit;
