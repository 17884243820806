import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  CustomButton,
  PRIMARY,
  SECONDARY,
  StyledBody,
  StyledHead,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import AsmtWorkApi from "../../../system/api/AsmtApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import {
  AdminRoutes,
  AsmtWorkRoutes,
  pageType,
} from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface AsmtDetailInfoProps {
  data: AsmtDetail;
  type?: pageType;
}

function AsmtDetailInfo({ data, type }: AsmtDetailInfoProps) {
  const history = useHistory();
  const user = useUserState();
  const Loading = useLoadingDispatch();

  const onUpdatePage = () => {
    history.push(`${AsmtWorkRoutes.root}/edit/${data.id}`);
  };

  const onUpdateByAdmin = () => {
    if (user.roles.includes("Admin")) {
      history.push(`${AdminRoutes.asmt}/edit/${data.id}`);
    } else {
      history.push(`${AsmtWorkRoutes.root}`);
    }
  };

  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const onDeleteAsmt = () => {
    if (window.confirm("과제 업무를 정말 삭제하시겠습니까?")) {
      Loading({ type: "LOADING" });
      AsmtWorkApi.DeleteAsmtwork(data.id)
        .then(() => {
          history.push(AsmtWorkRoutes.owned);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const onPrintHandler = () => {
    const html = document.querySelector("html");
    const printContents = document.getElementById("download_area")?.innerHTML;
    const printDiv = document.createElement("DIV");

    if (html && printContents) {
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    }
  };

  const leftBtn = { marginLeft: "10px" };

  if (data) {
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          {user.roles.includes("Admin") ? (
            <CustomButton iconCss="e-icons e-edit" onClick={onUpdateByAdmin}>
              관리자 수정
            </CustomButton>
          ) : (
            <></>
          )}
          {data.participants.findIndex((x) => x.name === user.name) !== -1 &&
          type === "View" ? (
            <>
              {data.status === "임시저장" && (
                <CustomButton
                  style={{ ...leftBtn, color: PRIMARY }}
                  iconCss="e-icons e-delete-1"
                  onClick={onDeleteAsmt}
                >
                  삭제
                </CustomButton>
              )}
              {data.status === "완료" || data.status === "중단" ? null : (
                <CustomButton
                  style={leftBtn}
                  iconCss="e-icons e-edit"
                  onClick={onUpdatePage}
                >
                  수정
                </CustomButton>
              )}
            </>
          ) : (
            <></>
          )}
          {user.roles.includes("Admin") ||
          data.participants.findIndex((x) => x.name === user.name) !== -1 ? (
            <CustomButton
              style={leftBtn}
              iconCss="e-icons e-download"
              onClick={onPrintHandler}
            >
              다운로드
            </CustomButton>
          ) : null}
        </Grid>
        <Grid item xs={12} id="download_area">
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledHead align="center">분류</StyledHead>
                  <StyledBody colSpan={data.status === "완료" ? 3 : 2}>
                    {data.classification}
                  </StyledBody>
                  <StyledHead align="center">등록일</StyledHead>
                  <StyledBody>
                    {data.createdAt === ""
                      ? "-"
                      : new Date(data.createdAt).toLocaleDateString()}
                  </StyledBody>
                </TableRow>
                <TableRow>
                  <StyledHead align="center">과제명</StyledHead>
                  <StyledBody colSpan={5}>{data.title}</StyledBody>
                </TableRow>
                <TableRow>
                  <StyledHead align="center">
                    과제 참여자({data.participants.length})
                  </StyledHead>
                  <StyledBody colSpan={5} style={{ padding: "5px" }}>
                    {data.participants
                      .filter(
                        (emp) =>
                          !emp.mentor ||
                          (emp.mentor &&
                            emp.contributionRate !== undefined &&
                            emp.contributionRate > 0)
                      )
                      .map((user, idx) => {
                        return (
                          <Chip
                            key={idx}
                            style={{
                              backgroundColor: user.projectManager
                                ? PRIMARY
                                : SECONDARY,
                              color: "white",
                              margin: "2px",
                            }}
                            label={
                              user.projectManager
                                ? "PM" +
                                  user.name +
                                  `(${
                                    user.contributionRate === undefined
                                      ? "-"
                                      : user.contributionRate
                                  })`
                                : user.name +
                                  `(${
                                    user.contributionRate === undefined
                                      ? "-"
                                      : user.contributionRate
                                  })`
                            }
                          />
                        );
                      })}
                  </StyledBody>
                </TableRow>
                <TableRow>
                  <StyledHead align="center">멘토</StyledHead>
                  <StyledBody
                    colSpan={5}
                    style={{ padding: "8px", height: "52px" }}
                  >
                    {data.participants
                      .filter((emp) => emp.mentor)
                      .map((user, idx) => {
                        return (
                          <Chip
                            key={idx}
                            style={{
                              backgroundColor: SECONDARY,
                              color: "white",
                              margin: "2px",
                            }}
                            label={user.name}
                          />
                        );
                      })}
                  </StyledBody>
                </TableRow>
                <TableRow>
                  <StyledHead align="center">시작일</StyledHead>
                  <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                    {data.startDate === "" || data.startDate === "Invalid date"
                      ? "-"
                      : new Date(data.startDate).toLocaleDateString()}
                  </StyledBody>
                  <StyledHead align="center">진행상태</StyledHead>
                  <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                    {data.status}
                    {data.subStatus ? <>-{data.subStatus}</> : null}
                  </StyledBody>
                  {data.status === "완료" && (
                    <>
                      <StyledHead align="center">종료일</StyledHead>
                      <StyledBody>
                        {data.endDate
                          ? new Date(data.endDate).toLocaleDateString()
                          : "-"}
                      </StyledBody>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <StyledHead align="center">예상 종료일</StyledHead>
                  <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                    {data.expectedEndDate === ""
                      ? "-"
                      : new Date(data.expectedEndDate).toLocaleDateString()}
                  </StyledBody>
                  <StyledHead align="center">성과급</StyledHead>
                  <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                    {data.meritPay
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </StyledBody>
                  {data.status === "완료" && (
                    <>
                      <StyledHead align="center">성과급 지급일</StyledHead>
                      <StyledBody>
                        {data.payPeriod
                          ? new Date(data.payPeriod).toLocaleDateString()
                          : "-"}
                      </StyledBody>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <StyledBody colSpan={6}>
                    <HtmlContent
                      style={{ minHeight: "300px" }}
                      data={
                        data.content ? data.content : "작성된 내용이 없습니다."
                      }
                    ></HtmlContent>
                  </StyledBody>
                </TableRow>
                <TableRow>
                  <StyledHead
                    align="center"
                    style={{ padding: "8px", height: "52px" }}
                  >
                    첨부파일
                  </StyledHead>
                  <StyledBody colSpan={5}>
                    {data.attachmentFiles
                      ? data.attachmentFiles.map((file, idx) => {
                          return (
                            <div key={idx} style={{ cursor: "pointer" }}>
                              <Typography
                                variant="body2"
                                onClick={() =>
                                  downloadFile(file.filename, file.filePath)
                                }
                              >
                                {file.filename}
                              </Typography>
                            </div>
                          );
                        })
                      : "-"}
                  </StyledBody>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  } else {
    return <>데이터가 없습니다.</>;
  }
}

export default AsmtDetailInfo;
