import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  CustomButton,
  DataGridContent,
  StyledBodyPa5,
  StyledHead,
  TextNoMargin,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import {
  ASMT_STATUS,
  ASMT_STATUS_ADMIN,
  ASMT_STATUS_GRID_ADMIN,
  SEARCH_NAME_INTERN,
  SearchYears,
} from "../../../system/Constants";
import AdminApi from "../../../system/api/AdminApi";
import { CommonWorkApi } from "../../../system/api/CommonApi";
import { searchDataState, searchDetailState } from "../../../system/atoms";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useUserState } from "../../../system/context/UserContext";
import { InternWorkList } from "../../../system/types/InternWork";
import { InitInternWorkList } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import StatusChip from "../../Common/StatusChip";
import TitleDiv from "../../Common/TitleDiv";

function InternTable() {
  const history = useHistory();
  const [searchData, setSearchData] = useRecoilState(searchDataState);
  const [searchDetail, setSearchDetail] = useRecoilState(searchDetailState);
  const [data, setData] = React.useState<InternWorkList>(InitInternWorkList);
  const fields: object = { text: "text", value: "value" };
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const [changeTime, setChangeTime] = useState(new Date());
  let grid: any;

  const moveToDetailPage = useCallback(
    (id: number) => {
      history.push({
        pathname: `${AsmtWorkRoutes.intern}/detail/${id}`,
      });
    },
    [history]
  );
  useEffect(() => {
    Loading({ type: "LOADING" });
    CommonWorkApi.GetAsmtWorkList("intern", "all", searchData)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading, searchData, changeTime]);

  const onPageChange = (args: any, value: number) => {
    setSearchData({ ...searchData, page: value - 1 });
  };

  const downloadFile = () => {
    CommonWorkApi.GetAsmtWorkList("intern", "all", {
      ...searchData,
      isFile: true,
      size: 100000,
    })
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onClickSearch = () => {
    setSearchData({
      ...searchData,
      searchType: searchDetail.type,
      searchWord: searchDetail.word,
      page: 0,
    });
  };

  const onChangeSearch = (args: any) => {
    setSearchDetail({
      ...searchDetail,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChange = (args: any) => {
    setSearchData({
      ...searchData,
      [args.target.name]: args.target.value,
      page: 0,
    });
  };

  const partTemplate = useCallback(
    (props: any): any => {
      let pmList: WorkUser[] = props.participants.filter(
        (x: WorkUser) => !x.mentor
      );
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {pmList.map((x) => x.department).join("/")}
        </DataGridContent>
      );
    },
    [moveToDetailPage]
  );

  const template = useCallback(
    (props: any): any => {
      let list: WorkUser[] = props.participants.filter(
        (x: WorkUser) => !x.mentor
      );
      if (list.length > 2) {
        return (
          <DataGridContent onClick={() => moveToDetailPage(props.id)}>
            {list[0].name + " 외 " + (list.length - 1) + "명"}
          </DataGridContent>
        );
      } else {
        return (
          <DataGridContent onClick={() => moveToDetailPage(props.id)}>
            {list.map((x) => x.name).join(",")}
          </DataGridContent>
        );
      }
    },
    [moveToDetailPage]
  );

  const mentorTemplate = useCallback(
    (props: any): any => {
      let mentorList: WorkUser[] = props.participants.filter(
        (x: WorkUser) => x.mentor
      );
      if (mentorList.length > 2) {
        return (
          <DataGridContent onClick={() => moveToDetailPage(props.id)}>
            {mentorList[0].name + " 외 " + (mentorList.length - 1) + "명"}
          </DataGridContent>
        );
      } else {
        return (
          <DataGridContent onClick={() => moveToDetailPage(props.id)}>
            {mentorList.map((x) => x.name).join(",")}
          </DataGridContent>
        );
      }
    },
    [moveToDetailPage]
  );

  const titleTemplate = useCallback(
    (props: any): any => {
      let InternTitle = props.title;
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {InternTitle}
        </DataGridContent>
      );
    },
    [moveToDetailPage]
  );
  const statusRef = useRef<any>([]);
  const onChangeStatus = (id: number, e: any, idx: number) => {
    const isConfirm = window.confirm(
      `해당 과제의 진행상태를 ${e.value}(으)로 변경하시겠습니까?`
    );
    if (isConfirm) {
      AdminApi.UpdateAdminStatus({
        workId: id,
        status: e.value,
      })
        .then(() => {
          alert("진행상태가 변경되었습니다.");
          setChangeTime(new Date());
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
          statusRef.current[idx].value = null;
        });
    } else {
      statusRef.current[idx].value = null;
    }
  };

  const statusTemplate = useCallback(
    (props: any): any => {
      let InternStatus = props.status;
      let InternSubStatus = props.subStatus;
      if (user.roles.includes("Admin")) {
        return (
          <DataGridContent>
            <DropDownListComponent
              ref={(scope) => {
                statusRef.current[props.index] = scope;
              }}
              id="ddlelement"
              dataSource={ASMT_STATUS_GRID_ADMIN}
              placeholder={
                props.subStatus
                  ? `${InternStatus}-${InternSubStatus}`
                  : InternStatus
              }
              onChange={(e: any) => onChangeStatus(props.id, e, props.index)}
            />
          </DataGridContent>
        );
      } else {
        if (props.subStatus) {
          return (
            <>
              {InternStatus}-{InternSubStatus}
            </>
          );
        } else {
          return (
            <>
              <StatusChip status={InternStatus} subStatus=""></StatusChip>
            </>
          );
        }
      }
    },
    [user.roles]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitleDiv title="인턴 과제 업무"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  기간검색
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="year"
                    value={searchData.year}
                    cssClass="e-outline"
                    dataSource={SearchYears}
                    onChange={onChange}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledHead width={"80"} align="center">
                  진행상태
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="status"
                    value={searchData.status}
                    cssClass="e-outline"
                    onChange={onChange}
                    fields={fields}
                    dataSource={
                      user.roles.includes("Admin")
                        ? ASMT_STATUS_ADMIN
                        : ASMT_STATUS
                    }
                  ></DropDownListComponent>
                </StyledBodyPa5>
              </TableRow>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  검색어
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="type"
                    cssClass="e-outline"
                    value={searchDetail.type}
                    onChange={onChangeSearch}
                    dataSource={SEARCH_NAME_INTERN}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledBodyPa5 align="center" colSpan={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <TextNoMargin>
                        <TextBoxComponent
                          placeholder="검색어를 입력하세요."
                          cssClass="e-outline"
                          name="word"
                          width="99%"
                          value={searchDetail.word}
                          onChange={onChangeSearch}
                        ></TextBoxComponent>
                      </TextNoMargin>
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end">
                      <CustomButton onClick={onClickSearch}>검색</CustomButton>
                    </Grid>
                  </Grid>
                </StyledBodyPa5>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right", marginTop: 1 }}>
        <CustomButton onClick={downloadFile} iconCss="e-icons e-download">
          엑셀 다운로드
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <GridComponent
          dataSource={data.works}
          //  rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="participants"
              template={partTemplate}
              headerText="파트"
              headerTextAlign="Center"
              textAlign="Center"
              width="120"
            />
            <ColumnDirective
              field="participants"
              template={template}
              headerText="이름"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="title"
              headerText="과제명"
              headerTextAlign="Center"
              width="450"
              template={titleTemplate}
            />
            <ColumnDirective
              field="participants"
              template={mentorTemplate}
              headerText="멘토"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="status"
              headerText="진행상태"
              width="150"
              headerTextAlign="Center"
              textAlign="Center"
              template={statusTemplate}
            />
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <Grid item xs={6}>
        <Pagination
          count={data.totalPages}
          page={searchData.page + 1}
          onChange={onPageChange}
          showFirstButton
          showLastButton
          defaultPage={10}
          boundaryCount={2}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign={"right"}>
          <span>
            {searchData.page + 1} of {data.totalPages} pages
          </span>
          <span>({data.totalElements} items)</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default InternTable;
