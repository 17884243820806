import { Grid } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { CustomButton, DatePickerCustom } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import UserApi from "../../../system/api/UserApi";
import { useUserState } from "../../../system/context/UserContext";
import { InitActionItemMeetingNoteRequestInfo } from "../../../system/types/initObject";
import { WorkUser } from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import SelectMultiEmployee from "../../Common/SelectMultiEmployee";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { ActionItemMeetingNoteRequestInfo } from "../../../system/types/ActionItem";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import AlertModal from "../../Common/AlertModal";

interface AIMeetingEditProps {
  id: number;
  meetingId: number | null;
  setType: (type: string) => void;
}

export default function AIMeetingEdit({
  id,
  meetingId,
  setType,
}: AIMeetingEditProps) {
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [data, setData] = useState<ActionItemMeetingNoteRequestInfo>(
    InitActionItemMeetingNoteRequestInfo
  );
  const [started, setStarted] = useState<boolean>(false);
  const params = {
    Width: "100%",
    Height: 800,
  };
  const loadingDispatch = useLoadingDispatch();
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (meetingId) {
      ActionItemsApi.getActionItemNoteDetail(id, "미팅노트", meetingId)
        .then((res) => {
          setData((prevData) => ({ ...res.data, setInfo: true }));
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    } else {
      UserApi.GetUsers(user.name)
        .then((res) => {
          setData((prevData) => ({
            ...prevData,
            participants: res.data,
            setInfo: true,
          }));
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [meetingId, id, user.name]);

  const saveComplete = () => {
    if (!started) {
      AlertModal(
        "check",
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );

      return;
    }

    // validation
    let flag = true;

    if (data.participants.length < 1) {
      AlertModal("check", "1명 이상의 참여자가 지정되어야 합니다.");
      flag = false;
      return;
    } else if (data.title === "" || data.meetingDate === "") {
      AlertModal("check", "모든 입력값을 채워주세요.");
      flag = false;
      return;
    }

    // 최종 저장
    if (flag && refContent.current) {
      const editor = refContent.current.editorRef;
      let promise: Promise<any>;
      loadingDispatch({ type: "LOADING" });

      if (meetingId) {
        promise = ActionItemsApi.updateActionItemNote(
          id,
          "미팅노트",
          meetingId,
          {
            ...data,
            content: editor.GetBodyValue() || "",
          }
        );
      } else {
        promise = ActionItemsApi.createActionItemNote(id, "미팅노트", {
          ...data,
          content: editor.GetBodyValue() || "",
        });
      }
      promise
        .then((res) => {
          setData(res.data);
          AlertModal("success", "정상적으로 등록되었습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          loadingDispatch({ type: "COMPLETE" });
          setData(InitActionItemMeetingNoteRequestInfo);
          setType("table");
        });
    }
  };

  let datePick: any;

  const onChange = (args: any) => {
    if (args.target.name === "meetingDate") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: moment(
          args.target.value
        ).format("YYYY-MM-DD"),
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt="10px">
        미팅주제
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          placeholder="미팅주제를 입력해 주세요."
          style={{ height: "48px" }}
          cssClass="e-outline"
          value={data.title}
          className="e-input"
          type="text"
          name="title"
          onChange={onChange}
        />
        {data.title.length === 0 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 미팅주제를
            입력해주세요.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        참여자
      </Grid>
      <Grid item xs={12}>
        <SelectMultiEmployee
          list={data.participants}
          onChange={onChangeEmp}
        ></SelectMultiEmployee>
        {data.participants.length < 1 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 참여자가 지정되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        미팅일자
      </Grid>
      <Grid item xs={12}>
        <DatePickerCustom>
          <DatePickerComponent
            placeholder="미팅일자를 입력해 주세요."
            name="meetingDate"
            format="yyyy-MM-dd"
            cssClass="e-outline e-custom"
            style={{ height: "48px" }}
            value={data?.meetingDate ? new Date(data.meetingDate) : undefined}
            onChange={onChange}
            focus={(e) => datePick.show()}
            ref={(scope) => {
              datePick = scope;
            }}
            width="30%"
          />
        </DatePickerCustom>
        {data.meetingDate === "" || data.meetingDate === "Invalid date" ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 미팅일자가 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        {data.setInfo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <CustomButton iconCss="e-icons e-check" onClick={saveComplete}>
          저장
        </CustomButton>
      </Grid>
    </Grid>
  );
}
