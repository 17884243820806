import { Grid } from "@mui/material";
import { AITableCardBox, AITableContentsBox } from "../../../styles/theme";
import { CustomText } from "../../../styles/CustomText";
import { Gray700 } from "../../../styles/Colors";
import { ActionItemDetailInfo } from "../../../system/types/ActionItem";
import CommentTable from "../../Common/Comment/CommentTable";
import { useEffect, useState } from "react";

interface AIDetailCommentProps {
  data: ActionItemDetailInfo | undefined;
  refreshData: () => void;
}

function AIDetailComment({ data, refreshData }: AIDetailCommentProps) {
  const [commentnumber, setCommentNumber] = useState(0);
  useEffect(() => {
    if (commentnumber) {
      refreshData();
    }
  }, [commentnumber, refreshData]);
  return (
    <AITableCardBox mt="10px">
      <Grid container item xs={12} alignItems="center" padding="10px">
        <CustomText type="subtitle" bold600 color={Gray700}>
          댓글
        </CustomText>
      </Grid>
      <AITableContentsBox container item xs={12} padding="5px">
        {data && data?.comments && (
          <CommentTable
            cType={"actionItem"}
            data={data}
            setCommentNumber={setCommentNumber}
          />
        )}
      </AITableContentsBox>
    </AITableCardBox>
  );
}

export default AIDetailComment;
