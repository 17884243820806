import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CustomButton } from "../../styles/theme";
import { NoticeApi } from "../../system/api/NoticeApi";
import { ErrorHandler } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { InitNotice } from "../../system/types/initObject";
import { Notice, NoticeRoutes } from "../../system/types/type";
import HtmlContent from "../Common/HtmlContent";
import TitleDiv from "../Common/TitleDiv";
interface Params {
  id: string;
}

function NoticeView() {
  const { id } = useParams<Params>();
  const noticeId = parseInt(id);
  const user = useUserState();
  const history = useHistory();
  const [data, setData] = useState<Notice>(InitNotice);

  useEffect(() => {
    if (noticeId) {
      NoticeApi.getNoticeDetail(noticeId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }, [noticeId]);

  const onDeleteNotice = () => {
    if (window.confirm("공지를 정말 삭제하시겠습니까?")) {
      if (data.id && user.roles.includes("Admin")) {
        NoticeApi.deleteNotice(data.id)
          .then(() => {
            history.push(NoticeRoutes.root);
          })
          .catch((err) => {
            alert("공지를 삭제할 수 없습니다.");
            let msg = ErrorHandler(err);
            alert(msg);
          });
      }
    }
  };

  const onUpdateNotice = () => {
    if (data.id && user.roles.includes("Admin")) {
      history.push({ pathname: `${NoticeRoutes.root}/edit/${data.id}` });
    }
  };

  //css
  const noticeBar = {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F0F2F5",
    borderTop: "0.5px solid #DCDFE3",
    borderLeft: "0.5px solid #DCDFE3",
    borderRight: "0.5px solid #DCDFE3",
    height: "52px",
  };
  const formStyle = {
    padding: "10px",
    minHeight: "500px",
    border: "0.5px solid #DCDFE3",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="공지사항 상세"></TitleDiv>
      </Grid>
      <Grid item xs={12} container style={noticeBar}>
        <Grid item xs={9}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            제목 : {data.title}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            sx={{ textAlign: "right", marginRight: "10px" }}
          >
            {data.writer.department} {data.writer.name} / {data.createdAt}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} style={formStyle}>
        <HtmlContent data={data.content}></HtmlContent>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: "0px", marginTop: "10px" }}>
        {user.roles.includes("Admin") && (
          <Grid display="flex" justifyContent="space-between">
            <CustomButton onClick={onUpdateNotice} iconCss="e-icons e-edit">
              수정
            </CustomButton>
            {user.name === data.writer.name && (
              <CustomButton
                onClick={onDeleteNotice}
                iconCss="e-icons e-delete-1"
              >
                삭제
              </CustomButton>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default NoticeView;
