import { Grid } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import { DatePickerCustom } from "../../../styles/theme";
import { ASMT_EDIT_CLASSIFICATION } from "../../../system/Constants";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import { WorkUser } from "../../../system/types/type";
import SelectExeEmployee from "../../Common/SelectExeEmployee";
import SelecPMEmployees from "../../Common/SelectPMEmployee";
import ContributionModal from "./ContributionModal";

interface BasicStepProps {
  data: AsmtDetail;
  onChangeData: (data: AsmtDetail) => void;
  mentorEmpList: WorkUser[];
  onChangeMentor: (mentor: WorkUser[]) => void;
}

function BasicStep({
  data,
  onChangeData,
  mentorEmpList,
  onChangeMentor,
}: BasicStepProps) {
  const onChange = (args: any) => {
    if (
      args.target.name === "expectedEndDate" ||
      args.target.name === "startDate"
    ) {
      onChangeData({
        ...data,
        [(args.target as HTMLInputElement).name]: moment(
          args.target.value
        ).format("YYYY-MM-DD"),
      });
    } else {
      onChangeData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };
  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      onChangeData({ ...data, participants: userList });
    }
    var newMentorList = [];
    for (let i = 0; i < mentorEmpList.length; i++) {
      let mentorInfo = mentorEmpList[i];
      if (
        userList.filter((e) => {
          return e.id === mentorInfo.id;
        }).length < 1
      ) {
        var newMentorListItem = { ...mentorEmpList[i], contributionRate: 0 };
        newMentorList.push(newMentorListItem);
      } else {
        newMentorList.push(mentorEmpList[i]);
      }
    }
    onChangeMentor(newMentorList);
  };

  const onChangeMentorList = (mentorList: WorkUser[]) => {
    var preParticipantsData = [];
    for (let i = 0; i < data.participants.length; i++) {
      if (
        mentorList.filter((item) => item.name === data.participants[i].name)
          .length > 0
      ) {
        preParticipantsData.push({ ...data.participants[i], mentor: true });
      } else {
        preParticipantsData.push({ ...data.participants[i], mentor: false });
      }
    }
    onChangeData({ ...data, participants: preParticipantsData });

    const newList: WorkUser[] = [];
    if (mentorList) {
      mentorList.forEach((mentor) => {
        const newMentor = { ...mentor, mentor: true };
        newList.push(newMentor);
      });
      onChangeMentor(newList);
    }
  };

  const onClickChip = (e: any, id: number) => {
    const newList = data.participants;
    const emp = data.participants.findIndex((x) => x.id === id);
    const state = newList[emp].projectManager;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], projectManager: !state };
    }
    onChangeData({ ...data, participants: newList });
  };

  let startdate: any;
  let enddate: any;

  const footText = { color: "#1E66D9", fontSize: "0.8rem" };
  const cntButton = { marginLeft: "10px", padding: "0px" };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        분류
      </Grid>
      <Grid item xs={3}>
        <DropDownListComponent
          value={data.classification}
          name="classification"
          cssClass="e-outline"
          dataSource={ASMT_EDIT_CLASSIFICATION}
          onChange={onChange}
          placeholder="분류를 선택해 주세요."
        ></DropDownListComponent>
        {!data.classification ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 분류가 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <div>과제명</div>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          value={data.title}
          placeholder="과제명을 입력해 주세요."
          name="title"
          onChange={onChange}
          style={{ height: "48px" }}
          cssClass="e-outline"
        ></TextBoxComponent>
        {data.title.length < 1 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 과제명이 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={6} container alignItems="center">
        <div>과제 참여자 등록</div>
        <div style={cntButton}>
          <ContributionModal
            onChangeEmp={onChangeEmp}
            list={data.participants}
            mentorEmpList={mentorEmpList}
          ></ContributionModal>
        </div>
      </Grid>
      <Grid
        item
        xs={6}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <div>과제 멘토</div>
        <div>
          <SelectExeEmployee
            list={mentorEmpList}
            onChange={onChangeMentorList}
          ></SelectExeEmployee>
        </div>
      </Grid>
      <Grid item xs={12}>
        <SelecPMEmployees
          list={data.participants}
          onChange={onChangeEmp}
          onClickChip={onClickChip}
        ></SelecPMEmployees>
        {data.participants.length < 1 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 참여자가 지정되어
            있지 않습니다.
          </div>
        ) : (
          <>
            {data.participants.length < 1 ||
            data.participants.filter((x) => x.projectManager).length === 0 ? (
              <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                <span className="e-icons e-circle-check"></span> PM 설정이 되어
                있지 않습니다. (1명 이상의 PM이 지정되어야 합니다.)
              </div>
            ) : (
              <>
                {data.participants
                  .filter((x) => x.contributionRate)
                  .reduce((x, y) => x + (y.contributionRate || 0), 0) !==
                100 ? (
                  <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                    <span className="e-icons e-circle-check"></span> 기여도
                    설정이 되어 있지 않습니다.
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={6}>
        <Grid item xs={12}>
          과제 시작일
        </Grid>
        <Grid item xs={12}>
          <DatePickerCustom>
            <DatePickerComponent
              placeholder="과제 시작일을 입력해 주세요."
              name="startDate"
              format="yyyy-MM-dd"
              value={new Date(data.startDate)}
              onChange={onChange}
              cssClass="e-outline e-custom"
              style={{ height: "48px" }}
              max={new Date(data.expectedEndDate)}
              focus={(e) => startdate.show()}
              ref={(scope) => {
                startdate = scope;
              }}
            />
          </DatePickerCustom>
        </Grid>
        {data.startDate === "" || data.startDate === "Invalid date" ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 과제 시작일이
            입력되어 있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid item xs={12}>
          과제 예상 종료일{" "}
          <span style={footText}>
            *예상 종료일은 등록 후 수정 시, 관리자 승인을 받아야 합니다.
          </span>
        </Grid>
        <Grid item xs={12}>
          {data.status === "임시저장" || data.status === "등록대기" ? (
            <DatePickerComponent
              placeholder="예상 종료일을 입력해 주세요."
              name="expectedEndDate"
              format="yyyy-MM-dd"
              value={new Date(data.expectedEndDate)}
              onChange={onChange}
              cssClass="e-outline"
              style={{ height: "48px" }}
              min={new Date(data.startDate)}
              focus={(e) => enddate.show()}
              ref={(scope) => {
                enddate = scope;
              }}
            />
          ) : (
            <div style={{ marginTop: "10px" }}>{data.expectedEndDate}</div>
          )}
        </Grid>
        {data.expectedEndDate === "" ||
        data.expectedEndDate === "Invalid date" ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 예상 종료일이
            입력되어 있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}

export default BasicStep;
