import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React from "react";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import CrossEditor from "../../Common/Editor/CrossEditor";

interface ContentStepProps {
  data: AsmtDetail;
  refContent: any;
  fileList: File[];
  onChangeFile: (file: File[]) => void;
  onChangeData: (data: AsmtDetail) => void;
  onLoaded: () => void;
}

function ContentStep({
  data,
  refContent,
  fileList,
  onChangeFile,
  onChangeData,
  onLoaded,
}: ContentStepProps) {
  const [nameList, setNameList] = React.useState<string[]>([]);

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      onChangeFile(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    onChangeFile([...uploadArr]);
  };

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      onChangeData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => idx !== i),
      });
    }
  };

  const params = {
    Width: "100%",
    Height: 800,
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {data.setInfo && (
            <CrossEditor
              ref={refContent}
              name="InBodyAssignmentWork"
              params={params}
              value={data.content}
              onLoaded={onLoaded}
            />
          )}
        </Grid>
        {data.id !== 0 && (
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            기존파일 :{" "}
            {data.attachmentFiles &&
              data.attachmentFiles.map((x, idx) => (
                <span
                  key={x.filename + idx}
                  style={{ fontSize: "14px", margin: "3px" }}
                >
                  {x.filename}
                  <ButtonComponent
                    style={{ marginLeft: "3px", fontSize: "14px" }}
                    cssClass="e-small e-danger"
                    onClick={() => deleteExistFile(idx)}
                  >
                    삭제
                  </ButtonComponent>
                </span>
              ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <input id="file" type="file" onChange={onSaveFile} multiple></input>
          <br />
          <div>
            {nameList &&
              nameList.map((e, idx) => (
                <div key={idx} style={{ fontSize: "14px" }}>
                  {e}
                  <IconButton
                    size="small"
                    style={{ padding: "0px" }}
                    onClick={() => deleteFile(idx)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ContentStep;
