import { Grid, Typography } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  AttachmentFileLayout,
  AttachmentFileLayoutTitle,
  CustomButton,
  CustomButtonRed,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { ASMT_EDIT_CLASSIFICATION } from "../../../system/Constants";
import AsmtWorkApi from "../../../system/api/AsmtApi";
import FileApi from "../../../system/api/fileApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import { InitAsmtDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import ContributionModal from "../../Asmt/Main/ContributionModal";
import CrossEditor from "../../Common/Editor/CrossEditor";
import { defaultValue } from "../../Common/Editor/defaultValue";
import SelectExeEmployee from "../../Common/SelectExeEmployee";
import SelecPMEmployees from "../../Common/SelectPMEmployee";
import TitleDiv from "../../Common/TitleDiv";

interface Params {
  id: string;
}

function validation(data: AsmtDetail, mentorEmpList: WorkUser[]) {
  var checkMentor = false;
  for (var i = 0; data.participants.length > i; i++) {
    if (
      mentorEmpList.filter(
        // eslint-disable-next-line no-loop-func
        (item) =>
          item.name === data.participants[i].name &&
          (data.participants[i].contributionRate === undefined ||
            data.participants[i].contributionRate === 0)
      ).length > 0
    ) {
      checkMentor = true;
    }
  }
  if (
    data.participants.length < 1 ||
    data.participants.filter((x) => x.projectManager).length === 0
  ) {
    alert("1명 이상의 PM이 지정되어야 합니다.");
    return false;
  } else if (
    data.participants
      .filter((x) => x.contributionRate)
      .reduce((x, y) => x + (y.contributionRate || 0), 0) !== 100
  ) {
    alert("모든 참여자 기여도가 0이상, 기여도 합이 100이 되어야 합니다.");
    return false;
  } else if (data.classification === "") {
    alert("분류를 선택해 주세요.");
    return false;
  } else if (
    data.expectedEndDate === "" ||
    data.expectedEndDate === "Invalid date"
  ) {
    alert("예상 종료일을 정확히 입력해 주세요.");
    return false;
  } else if (checkMentor) {
    alert(
      "과제 멘토를 과제 참여자로 등록하려면 \n 과제 멘토 기여도를 0 보다 큰 숫자로 입력해주셔야 합니다."
    );
    return false;
  }
  return true;
}

function AsmtAdminEdit() {
  const Loading = useLoadingDispatch();
  const refContent = createRef<CrossEditor>();
  const { id } = useParams<Params>();
  const asmtId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<AsmtDetail>(InitAsmtDetail);
  const [mentorEmpList, setMentorEmpList] = useState<WorkUser[]>([]);
  const [started, setStarted] = useState<boolean>(false);
  let payPeriod: any;
  let enddate: any;
  let expectedEndDate: any;
  var submitcheck = false;
  const params = {
    Width: "100%",
    Height: 800,
  };
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (asmtId) {
      Loading({ type: "LOADING" });
      AsmtWorkApi.GetAsmtworkDetail(asmtId)
        .then((res) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(res.data.content, "text/html");
          const tableElement = doc.querySelector(
            'table[name="namo-editor-table-class"]'
          );
          if (tableElement) {
            setData({
              ...res.data,
              participants: res.data.participants.filter(
                (x) =>
                  !x.mentor ||
                  (x.mentor &&
                    x.contributionRate !== undefined &&
                    x.contributionRate > 0)
              ),
              setInfo: true,
            });
          } else {
            setData({
              ...res.data,
              participants: res.data.participants.filter(
                (x) =>
                  !x.mentor ||
                  (x.mentor &&
                    x.contributionRate !== undefined &&
                    x.contributionRate > 0)
              ),
              content: defaultValue + res.data.content,
              setInfo: true,
            });
          }
          setMentorEmpList(res.data.participants.filter((mt) => mt.mentor));
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asmtId]);

  const onChange = (args: any) => {
    if (
      args.target.name === "expectedEndDate" ||
      args.target.name === "endDate" ||
      args.target.name === "payPeriod"
    ) {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: moment(
          args.target.value
        ).format("YYYY-MM-DD"),
      });
    } else if (args.target.name === "status" && args.target.value === "완료") {
      setData({
        ...data,
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        payPeriod: moment(new Date()).format("YYYY-MM-DD"),
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
    var newMentorList = [];
    for (var i = 0; i < mentorEmpList.length; i++) {
      var mentorInfo = mentorEmpList[i];
      // eslint-disable-next-line no-loop-func
      if (userList.filter((e) => e.id === mentorInfo.id).length < 1) {
        var newMentorListItem = { ...mentorEmpList[i], contributionRate: 0 };
        newMentorList.push(newMentorListItem);
      } else {
        newMentorList.push(mentorEmpList[i]);
      }
    }
    setMentorEmpList(newMentorList);
  };

  const onChangeMentorList = (mentorList: WorkUser[]) => {
    var preParticipantsData = [];
    for (var i = 0; i < data.participants.length; i++) {
      if (
        // eslint-disable-next-line no-loop-func
        mentorList.filter((item) => item.name === data.participants[i].name)
          .length > 0
      ) {
        preParticipantsData.push({ ...data.participants[i], mentor: true });
      } else {
        preParticipantsData.push({ ...data.participants[i], mentor: false });
      }
    }
    setData({ ...data, participants: preParticipantsData });
    const newList: WorkUser[] = [];
    if (mentorList) {
      mentorList.forEach((mentor) => {
        const newMentor = { ...mentor, mentor: true };
        newList.push(newMentor);
      });
      setMentorEmpList(newList);
    }
  };

  const onClickChip = (e: any, id: number) => {
    const newList = data.participants;
    const emp = data.participants.findIndex((x) => x.id === id);
    const state = newList[emp].projectManager;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], projectManager: !state };
    }
    setData({ ...data, participants: newList });
  };

  const onSave = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;
      submitcheck = true;
      if (validation(data, mentorEmpList)) {
        Loading({ type: "LOADING" });
        AsmtWorkApi.UpdateAsmtworkDetail({
          ...data,
          content: editor.GetBodyValue() || "",
          participants: data.participants.concat(mentorEmpList),
        })
          .then(() => {
            alert("과제가 수정되었습니다.");
            history.push(`${AsmtWorkRoutes.root}/detail/${data.id}`);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
            alert("변경사항을 저장하는 중 오류가 발생했습니다.");
          })
          .finally(() => Loading({ type: "COMPLETE" }));
      }
    }
  };

  const onDelete = () => {
    if (window.confirm("삭제 후 복구할 수 없습니다. 정말 삭제하시겠습니까?")) {
      Loading({ type: "LOADING" });
      AsmtWorkApi.DeleteAsmtwork(data.id)
        .then(() => {
          alert("과제가 삭제되었습니다.");
          history.push(AsmtWorkRoutes.root);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  };

  const onCancel = () => {
    history.push(`${AsmtWorkRoutes.root}/detail/${data.id}`);
  };

  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const cntButton = { marginLeft: "10px", padding: "0px" };
  const boxStyle = {
    padding: "10px",
    border: "1px solid #DCDFE3",
    height: "48px",
  };

  if (data) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TitleDiv title="과제 수정(관리자)"></TitleDiv>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Grid item xs={12}>
                분류
              </Grid>
              <Grid item xs={12} style={{ height: "48px" }}>
                <DropDownListComponent
                  cssClass="e-outline"
                  name="classification"
                  onChange={onChange}
                  value={data.classification}
                  dataSource={ASMT_EDIT_CLASSIFICATION}
                  placeholder="분류를 선택해 주세요."
                  style={{ height: "48px" }}
                ></DropDownListComponent>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                등록일
              </Grid>
              <Grid item xs={12} style={boxStyle}>
                {data.createdAt
                  ? new Date(data.createdAt).toLocaleDateString()
                  : "-"}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                과제명
              </Grid>
              <Grid item xs={12}>
                <TextBoxComponent
                  value={data.title}
                  placeholder="과제명을 입력해 주세요."
                  name="title"
                  onChange={onChange}
                  style={{ height: "48px" }}
                  cssClass="e-outline"
                ></TextBoxComponent>
                {data.title.length < 1 ? (
                  <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                    <span className="e-icons e-circle-check"></span> 과제명이
                    입력되어 있지 않습니다.
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <div>과제 참여자 등록</div>
              <div style={cntButton}>
                <ContributionModal
                  onChangeEmp={onChangeEmp}
                  list={data.participants}
                  mentorEmpList={mentorEmpList}
                ></ContributionModal>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <div>과제 멘토</div>
              <div>
                <SelectExeEmployee
                  list={mentorEmpList}
                  onChange={onChangeMentorList}
                ></SelectExeEmployee>
              </div>
            </Grid>
            <Grid item xs={12}>
              <SelecPMEmployees
                list={data.participants}
                onChange={onChangeEmp}
                onClickChip={onClickChip}
              ></SelecPMEmployees>
              {data.participants.length < 1 ? (
                <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                  <span className="e-icons e-circle-check"></span> 참여자가
                  지정되어 있지 않습니다.
                </div>
              ) : (
                <>
                  {data.participants.length < 1 ||
                  data.participants.filter((x) => x.projectManager).length ===
                    0 ? (
                    <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                      <span className="e-icons e-circle-check"></span> PM 설정이
                      되어 있지 않습니다. (1명 이상의 PM이 지정되어야 합니다.)
                    </div>
                  ) : (
                    <>
                      {data.participants
                        .filter((x) => x.contributionRate)
                        .reduce((x, y) => x + (y.contributionRate || 0), 0) !==
                      100 ? (
                        <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                          <span className="e-icons e-circle-check"></span>{" "}
                          기여도 설정이 되어 있지 않습니다.
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                과제 시작일
              </Grid>
              <Grid item xs={12} style={boxStyle}>
                {data.startDate
                  ? new Date(data.startDate).toLocaleDateString()
                  : "-"}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                과제 예상 종료일
              </Grid>
              <Grid item xs={12}>
                <DatePickerComponent
                  placeholder="예상 종료일을 입력해 주세요."
                  name="expectedEndDate"
                  format="yyyy-MM-dd"
                  value={new Date(data.expectedEndDate)}
                  onChange={onChange}
                  cssClass="e-outline"
                  style={{ height: "48px" }}
                  min={new Date(data.startDate)}
                  focus={(e) => expectedEndDate.show()}
                  ref={(scope) => {
                    expectedEndDate = scope;
                  }}
                />
              </Grid>
              {data.expectedEndDate === "" ||
              data.expectedEndDate === "Invalid date" ? (
                <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                  <span className="e-icons e-circle-check"></span> 예상 종료일이
                  입력되어 있지 않습니다.
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12}>
                진행상태
              </Grid>
              <Grid item xs={12}>
                <DropDownListComponent
                  dataSource={["등록대기", "진행", "완료", "중단"]}
                  value={data.status}
                  onChange={onChange}
                  name="status"
                  cssClass="e-outline"
                  style={{ height: "48px" }}
                />
              </Grid>
            </Grid>
            {data.status === "완료" ? (
              <Grid item xs={3}>
                <Grid item xs={12}>
                  과제 종료일
                </Grid>
                <Grid item xs={12}>
                  <DatePickerComponent
                    placeholder="예상 종료일을 입력해 주세요."
                    name="endDate"
                    format="yyyy-MM-dd"
                    value={new Date(data.endDate)}
                    onChange={onChange}
                    cssClass="e-outline"
                    style={{ height: "48px" }}
                    min={new Date(data.startDate)}
                    focus={(e) => enddate.show()}
                    ref={(scope) => {
                      enddate = scope;
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={3}>
                <Grid item xs={12}>
                  세부상태
                </Grid>
                <Grid item xs={12}>
                  <TextBoxComponent
                    value={data.subStatus}
                    placeholder="세부상태를 입력해 주세요."
                    name="subStatus"
                    onChange={onChange}
                    style={{ height: "48px" }}
                    cssClass="e-outline"
                  ></TextBoxComponent>
                </Grid>
              </Grid>
            )}

            <Grid item xs={data.status === "완료" ? 3 : 6}>
              <Grid item xs={12}>
                성과급
              </Grid>
              <Grid item xs={12}>
                <NumericTextBoxComponent
                  value={data.meritPay}
                  placeholder="성과급을 입력해 주세요."
                  name="meritPay"
                  onChange={onChange}
                  cssClass="e-outline"
                  format={"n"}
                  min={0}
                  style={{ height: "48px" }}
                ></NumericTextBoxComponent>
              </Grid>
            </Grid>
            {data.status === "완료" && (
              <Grid item xs={3}>
                <Grid item xs={12}>
                  성과급 지급일
                </Grid>
                <Grid item xs={12}>
                  <DatePickerComponent
                    placeholder="성과급 지급일을 입력해 주세요."
                    name="payPeriod"
                    format="yyyy-MM-dd"
                    value={new Date(data.payPeriod)}
                    onChange={onChange}
                    cssClass="e-outline"
                    style={{ height: "48px" }}
                    min={new Date(data.startDate)}
                    focus={(e) => payPeriod.show()}
                    ref={(scope) => {
                      payPeriod = scope;
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {data.setInfo && (
                <CrossEditor
                  ref={refContent}
                  name="InBodyAssignmentWork"
                  params={params}
                  value={data.content}
                  onLoaded={onLoaded}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <AttachmentFileLayout>
                <AttachmentFileLayoutTitle>첨부파일</AttachmentFileLayoutTitle>
                <div>
                  {data.attachmentFiles
                    ? data.attachmentFiles.map((file, idx) => {
                        return (
                          <div key={idx} style={{ cursor: "pointer" }}>
                            <Typography
                              variant="body2"
                              onClick={() =>
                                downloadFile(file.filename, file.filePath)
                              }
                            >
                              {file.filename}
                            </Typography>
                          </div>
                        );
                      })
                    : "-"}
                </div>
              </AttachmentFileLayout>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "left", marginTop: "10px" }}>
          <CustomButton iconCss="e-icons e-chevron-left" onClick={onCancel}>
            취소
          </CustomButton>
        </Grid>
        <Grid item xs={10} sx={{ textAlign: "right", marginTop: "10px" }}>
          <CustomButton
            iconCss="e-icons e-delete-1"
            onClick={onDelete}
            style={{ marginRight: "10px" }}
          >
            삭제
          </CustomButton>
          <CustomButtonRed iconCss="e-icons e-check" onClick={onSave}>
            저장
          </CustomButtonRed>
        </Grid>
      </Grid>
    );
  } else return <>데이터를 조회할 수 없습니다.</>;
}

export default AsmtAdminEdit;
