import { Autocomplete, Box, Chip } from "@mui/material";
import { useMemo, useState } from "react";
import { CustomTextField, PRIMARY, SECONDARY } from "../../styles/theme";
import UserApi from "../../system/api/UserApi";
import { ErrorHandler } from "../../system/ApiService";
import { WorkUser } from "../../system/types/type";

interface SelecPMEmployeesProps {
  list?: WorkUser[];
  onChange: (data: WorkUser[]) => void;
  onClickChip: (e: any, id: number) => void;
}

function SelecPMEmployees({
  list,
  onChange: _onChange,
  onClickChip: _onClickChip,
}: SelecPMEmployeesProps) {
  const [data, setData] = useState<WorkUser[]>([]);

  useMemo(() => {
    UserApi.GetUsers("")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  const boxSpan = {
    marginLeft: "20px",
    fontSize: "14px",
    color: "#888888",
  };

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={data}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        onChange={(event: any, newValue: WorkUser[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(option: WorkUser, value: WorkUser) =>
          option.id === value.id
        }
        getOptionLabel={(option: WorkUser) => option.name}
        renderOption={(props, option: WorkUser) => (
          <Box component="li" {...props}>
            {option.name}
            <span
              style={boxSpan}
            >{`\t ${option.team}, ${option.jobPosition}`}</span>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder="참여자 이름을 클릭하여 역할(PM, 일반 참여자)를 변경할 수 있습니다."
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              style={{
                backgroundColor: option.projectManager ? PRIMARY : SECONDARY,
                color: "white",
              }}
              onClick={(e: any) => _onClickChip(e, option.id)}
              label={
                option.projectManager
                  ? "PM " +
                    option.name +
                    `(${
                      option.contributionRate === undefined
                        ? 0
                        : option.contributionRate
                    })`
                  : option.name +
                    `(${
                      option.contributionRate === undefined
                        ? 0
                        : option.contributionRate
                    })`
              }
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </>
  );
}

export default SelecPMEmployees;
