import client from "../ApiService";
import HttpClient from "../http-client";
import { AsmtSearch } from "../types/AsmtWork";
import { InternWorkDetail } from "../types/InternWork";

const baseUri: string = "/asmt/work/intern";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _InternApi extends _BasicApi {
  GetInternWorkList(searchData: AsmtSearch) {
    return this.client.get(`${baseUri}/all`, { params: searchData });
  }
  getInternWorkDetail(id: number) {
    return this.client.get<InternWorkDetail>(`${baseUri}/detail/${id}`);
  }
  createInternWork(InternWork: InternWorkDetail) {
    return this.client.post<InternWorkDetail>(`${baseUri}/create`, InternWork);
  }
  updateInternWork(InternWork: InternWorkDetail) {
    return this.client.put<InternWorkDetail>(`${baseUri}/update`, InternWork);
  }
  deleteInternWork(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
}

export const InternApi = new _InternApi(client);
