import client from "../ApiService";
import HttpClient from "../http-client";
import { AggSearch, AggSearchEmployee } from "../types/Statistics";

const baseUri: string = "/asmt/work/aggregation";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _StatisticsApi extends _BasicApi {
  GetEmployeeStatistics(data: AggSearchEmployee) {
    return this.client.get(`${baseUri}/employee`, data.isFile ? {params : data, responseType: 'blob'} : { params: data });
  }
  GetExecutiveStatistics(year: string) {
    return this.client.get(`${baseUri}/executive`, { params: { year: year } });
  }
  GetPartStatistics(year: string) {
    return this.client.get(`${baseUri}/part`, { params: { year: year } });
  }
  GetTeamStatistics(year: string, department?: string) {
    return this.client.get(`${baseUri}/team`, { params: { year: year, department: department } });
  }
  GetTeamDetailStatistics(data: AggSearch) {
    return this.client.get(`${baseUri}/team/detail`, { params: data });
  }
  GetTotalStatistics(year: string) {
    return this.client.get(`${baseUri}/total`, { params: { year: year } });
  }
  GetStateStatistics(year: string) {
    return this.client.get(`${baseUri}/total/count`, { params: { year: year } });
  }
}

const StatisticsApi = new _StatisticsApi(client);
export default StatisticsApi;
