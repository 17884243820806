import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DailyWorkRoutes } from "../../system/types/type";
import TitleDiv from "../Common/TitleDiv";

// mui
import { Grid } from "@mui/material";

// syncfusion
import { CustomButton, CustomButtonRed } from "../../styles/theme";
import { DailyApi } from "../../system/api/DailyApi";
import { ErrorHandler } from "../../system/ApiService";
import { dailyWork } from "../../system/types/dailyWork";

function DailyDetail() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [data, setData]: any = useState<dailyWork[]>([]);
  const [name, setName] = useState("");
  const [team, setteam] = useState("");

  // 정보 받아오기

  useEffect(() => {
    DailyApi.getDailyWorkDetail(Number(id))
      .then((res) => {
        setData(res.data);
        setName(res.data.owner.name);
        setteam(res.data.owner.team);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [id]);

  // 수정페이지로
  function gotoEdit() {
    history.push({
      pathname: `${DailyWorkRoutes.root}/edit/${id}`,
    });
  }

  function deleteButton() {
    if (window.confirm("일일업무를 정말 삭제하시겠습니까?")) {
      DailyApi.deleteDailyWork(data.workDate)
        .then(() => {
          history.push("/daily/work");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  }

  // css
  const topStyle = {
    borderTop: "2px solid #B3BBC5",
    borderBottom: "2px solid #B3BBC5",
    padding: "10px",
    backgroundColor: "#F8F8F8",
    margin: "10px 0px",
    alignItems: "center",
  };

  const userInfo = {
    padding: "5px",
  };

  const item = {
    borderLeft: "2px solid #B3BBC5",
    padding: "10px",
  };

  const textBoxTitle = {
    width: "110px",
    fontWeight: "bold",
  };

  const textBox = {
    borderBottom: "2px solid #B3BBC5",
    margin: "10px 30px",
    height: "230px",
    display: "flex",
  };

  const textArea = {
    marginBottom: "20px",
    marginLeft: "20px",
    width: "100%",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title=" 일일업무 보고 상세"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={topStyle}>
          <Grid item xs={4} style={userInfo}>
            성명 : {name}
          </Grid>
          <Grid item xs={5} style={item}>
            소속 : {team}
          </Grid>

          <Grid item xs={3} style={item}>
            날짜 : {data.workDate}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}>오늘 할 일</div>
        <pre style={textArea}>{data.todayWorks}</pre>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}>이슈</div>

        <pre style={textArea}>{data.issue}</pre>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}> 내일 할 일</div>
        <pre style={textArea}>{data.tomorrowWorks}</pre>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right", margin: "30px" }}>
        <CustomButton
          iconCss="e-icons e-edit"
          id="element"
          onClick={gotoEdit}
          style={{ marginRight: "10px" }}
        >
          수정
        </CustomButton>
        <CustomButtonRed
          iconCss="e-icons e-delete-1"
          id="element"
          onClick={deleteButton}
        >
          삭제
        </CustomButtonRed>
      </Grid>
    </Grid>
  );
}

export default DailyDetail;
