import { Grid } from "@mui/material";
import {
  Filter,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import MeetingNoteApi from "../../../system/api/MeetingNoteApi";
import { MeetingNoteList } from "../../../system/types/MeetingNote";
import { Ilocation, MeetingNoteRoutes } from "../../../system/types/type";

interface MeetingTableProps {
  parent: Ilocation;
}

function MeetingTable({ parent }: MeetingTableProps) {
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  const history = useHistory();
  const [data, setData] = useState<MeetingNoteList[]>([]);

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      history.push({
        pathname: `${MeetingNoteRoutes.root}/detail/${selectedrecords[0].id}`,
        state: parent,
      });
    }
  };

  useEffect(() => {
    MeetingNoteApi.getMeetingNoteList(parent.parentId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [parent.parentId]);

  const onWriteMeetingNote = () => {
    history.push({
      pathname: `${MeetingNoteRoutes.root}/edit`,
      state: parent,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <CustomButton onClick={onWriteMeetingNote} iconCss="e-icons e-plus">
          미팅노트 작성
        </CustomButton>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="meetingDate"
              headerText="미팅일자"
              textAlign="Center"
              width="200"
            />
            <ColumnDirective
              field="title"
              headerText="미팅주제"
              headerTextAlign="Center"
              width="500"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default MeetingTable;
