import { Grid } from "@mui/material";
import ExecutivesDetail from "../../../components/Admin/Statistics/ExecutivesDetail";

interface Props {
  handleOpen: (open: boolean) => void;
}
function StatisticsMeritpct({ handleOpen }: Props) {
  handleOpen(false);
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const searchWord = urlParams.get("searchWord");

  return (
    <>
      {year && searchWord ? (
        <Grid item xs={12}>
          <ExecutivesDetail
            year={year}
            searchWord={searchWord}
          ></ExecutivesDetail>
        </Grid>
      ) : null}
    </>
  );
}

export default StatisticsMeritpct;
