import client from "../ApiService";
import HttpClient from "../http-client";
import { AsmtSearch } from "../types/AsmtWork";

const baseUri: string = "/asmt/work";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _CommonWorkApi extends _BasicApi {
  GetAsmtWorkList(workType: string, searchRange: string, searchData: AsmtSearch) {
    return this.client.get(`${baseUri}/${workType}/${searchRange}`, 
    searchData.isFile ? {params : searchData, responseType: 'blob'} : { params: searchData });
  }
}

export const CommonWorkApi = new _CommonWorkApi(client);
