import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorHandler } from "../../system/ApiService";
import ReportNoteApi from "../../system/api/ReportApi";
import { ReportDetail } from "../../system/types/Report";
import { InitReportDetail } from "../../system/types/initObject";
import { ReportRoutes } from "../../system/types/type";

import { Chip, Grid } from "@mui/material";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import {
  BasicTable,
  BasicTr,
  CustomButton,
  SECONDARY,
} from "../../styles/theme";
import HrApi from "../../system/api/HrApi";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import HtmlContent from "../Common/HtmlContent";
import TitleDiv from "../Common/TitleDiv";

interface Params {
  id: string;
}

function ApproveView() {
  const { id } = useParams<Params>();
  const Loading = useLoadingDispatch();
  const reportId = parseInt(id);
  const [data, setData] = useState<ReportDetail>(InitReportDetail);
  const history = useHistory();
  const [editButton, setEditButton] = useState(false);
  const [graphdata, setGraphData]: any = useState([]);
  const [ceoReportEndTime, setCeoReportEndTime] = useState("");
  let datePick: any;

  useEffect(() => {
    if (reportId) {
      Loading({ type: "LOADING" });
      ReportNoteApi.getReportDetail(reportId)
        .then((res) => {
          setData(res.data);
          setCeoReportEndTime(res.data.endReportTime);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    } else {
      alert("잘못된 접근입니다.");
      history.push(ReportRoutes.root);
    }
  }, [Loading, history, reportId]);

  const onApprove = () => {
    Loading({ type: "LOADING" });
    HrApi.createApprove({
      title: data.title,
      parentTitle: data.parentTitle,
      content: data.content,
      type: data.type,
      startReportTime: moment(data.startReportTime).format(
        "yyyy-MM-DD hh:mm:ss"
      ),
      endReportTime: moment(data.endReportTime).format("yyyy-MM-DD hh:mm:ss"),
      attendees: data.participants,
      reportTarget: 0,
    })
      .then(() => {
        alert("승인되었습니다.");
        window.close();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onChangeEdit = () => {
    setEditButton(true);
  };

  const onCancelEdit = () => {
    setEditButton(false);
  };

  const onSave = () => {
    // validation
    let flag = true;
    if (
      data.startReportTime.split(" ")[1] === "00:00:00" ||
      ceoReportEndTime === ""
    ) {
      alert("모든 입력값을 채워주세요.");
      flag = false;
      return;
    }

    // 최종 저장
    if (flag) {
      Loading({ type: "LOADING" });
      ReportNoteApi.updateReport({
        ...data,
        endReportTime: ceoReportEndTime,
      })
        .then((res) => {
          setData(res.data);
          alert("정상적으로 등록되었습니다.");
          setEditButton(false);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const onChangeDate = (args: any) => {
    if (args.target.value) {
      var pickDate = args.target.value;
      let month = pickDate.getMonth() + 1;
      let day = pickDate.getDate();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      const date = `${pickDate.getFullYear()}-${month}-${day} 00:00:00`;
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: date,
      });
      var graphdate = pickDate.getFullYear() + "-" + month + "-" + day;
      ReportNoteApi.getCalendar("jiaiha@inbody.com", graphdate)
        .then((res) => {
          setGraphData(
            res.data.availableTimes.times
              .filter((dt: { enable: boolean }) => dt.enable === false)
              .map((dt: { time: any }) => dt.time)
          );
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const onChange = (args: any) => {
    if (args.target.name === "startReportTime" && args.target.value) {
      var pickDate = args.target.value;
      let month = pickDate.getMonth() + 1;
      let day = pickDate.getDate();
      let hour = pickDate.getHours();
      let minute = pickDate.getMinutes();
      let second = pickDate.getSeconds();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;
      second = second >= 10 ? second : "0" + second;

      var date =
        pickDate.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: date,
      });
    } else if (args.target.name === "ceoReportEndTime" && args.target.value) {
      var startDate = new Date(data.startReportTime);

      let month = startDate.getMonth() + 1;
      let day = startDate.getDate();

      var pickDate = args.target.value;
      let hour = pickDate.getHours();
      let minute = pickDate.getMinutes();
      let second = pickDate.getSeconds();

      let pickMonth = month >= 10 ? month : "0" + month;
      let pickDay = day >= 10 ? day : "0" + day;

      let pickHour = hour >= 10 ? hour : "0" + hour;
      let pickMinute = minute >= 10 ? minute : "0" + minute;
      let pickSecond = second >= 10 ? second : "0" + second;

      var date =
        startDate.getFullYear() +
        "-" +
        pickMonth +
        "-" +
        pickDay +
        " " +
        pickHour +
        ":" +
        pickMinute +
        ":" +
        pickSecond;
      setCeoReportEndTime(date);
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  var dateDisabled = new Date();
  let yesterday = dateDisabled.getDate();
  const minDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    yesterday
  );
  const minTime = new Date("1/1/2024 9:00 AM");
  const maxTime = new Date("1/1/2024 6:00 PM");
  const minStartTime = new Date(data.startReportTime);

  function disabledDate(args: { text: string; isDisabled: boolean }) {
    if (graphdata) {
      for (var i = 0; i < graphdata.length; i++) {
        if (graphdata[i][0] === "0") {
          var checkTime = graphdata[i].slice(1);
          if (args.text === checkTime) {
            args.isDisabled = true;
          }
        } else {
          if (args.text === graphdata[i]) {
            args.isDisabled = true;
          }
        }
      }
    }
  }

  // css
  const buttonGroup = { marginBottom: "30px" };
  const chip = { backgroundColor: SECONDARY, color: "white" };
  const deleteBtn = { marginRight: "10px" };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TitleDiv title="보고 일정 승인"></TitleDiv>
        </Grid>
        <Grid item xs={12} style={buttonGroup} sx={{ textAlign: "right" }}>
          {editButton ? (
            <CustomButton
              style={deleteBtn}
              iconCss="e-icons e-delete-1"
              onClick={onSave}
            >
              수정 완료
            </CustomButton>
          ) : (
            <CustomButton
              style={deleteBtn}
              iconCss="e-icons e-delete-1"
              onClick={onChangeEdit}
            >
              수정
            </CustomButton>
          )}
          {editButton ? (
            <CustomButton iconCss="e-icons e-check" onClick={onCancelEdit}>
              취소
            </CustomButton>
          ) : (
            <CustomButton iconCss="e-icons e-check" onClick={onApprove}>
              승인
            </CustomButton>
          )}
        </Grid>
        <BasicTable>
          <tbody>
            <BasicTr>
              <th>보고서명</th>
              <td colSpan={3}>{data.title}</td>
            </BasicTr>
            <BasicTr>
              <th>참여자</th>
              <td colSpan={4}>
                {data.participants.map((user, idx) => {
                  return <Chip key={idx} style={chip} label={user.name} />;
                })}{" "}
              </td>
            </BasicTr>
            <BasicTr>
              <th>시작 시간</th>
              <td>
                {data.startReportTime === null
                  ? "-"
                  : new Date(data.startReportTime).toLocaleString()}
              </td>
              <th>종료 시간</th>
              <td>
                {data.endReportTime === null
                  ? ""
                  : new Date(data.endReportTime).toLocaleString()}
              </td>
            </BasicTr>

            {editButton ? (
              <td colSpan={4}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div>보고일정</div>
                    <DatePickerComponent
                      name="startReportTime"
                      value={new Date(data.startReportTime)}
                      min={minDate}
                      format="yyyy-MM-dd "
                      onChange={onChangeDate}
                      placeholder="날짜를 선택해 주세요."
                      cssClass="e-outline e-custom"
                      style={{ height: "48px" }}
                      focus={(e) => datePick.show()}
                      ref={(scope) => {
                        datePick = scope;
                      }}
                      showClearButton={false}
                    ></DatePickerComponent>
                    {data.startReportTime.length === 0 ? (
                      <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                        <span className="e-icons e-circle-check"></span>{" "}
                        보고일정이 입력되어 있지 않습니다.
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {data.startReportTime === "" ? (
                    ""
                  ) : (
                    <Grid item xs={4}>
                      <div>시작 시간</div>
                      <TimePickerComponent
                        name="startReportTime"
                        id="time"
                        onChange={onChange}
                        value={new Date(data.startReportTime)}
                        itemRender={disabledDate}
                        placeholder="시간을 선택해 주세요."
                        style={{ height: "40px" }}
                        min={minTime}
                        max={maxTime}
                        showClearButton={false}
                      />
                      {data.startReportTime.split(" ")[1] === "00:00:00" ? (
                        <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                          <span className="e-icons e-circle-check"></span> 시작
                          시간이 입력되어 있지 않습니다.
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  )}
                  {data.startReportTime === "" ||
                  data.startReportTime.split(" ")[1] === "00:00:00" ? (
                    ""
                  ) : (
                    <Grid item xs={4}>
                      <div>종료 시간</div>
                      <TimePickerComponent
                        name="ceoReportEndTime"
                        id="time"
                        onChange={onChange}
                        value={new Date(data.endReportTime)}
                        itemRender={disabledDate}
                        placeholder="시간을 선택해 주세요."
                        style={{ height: "40px" }}
                        min={minStartTime}
                        max={maxTime}
                        showClearButton={false}
                      />
                      {ceoReportEndTime === "" ? (
                        <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                          <span className="e-icons e-circle-check"></span> 종료
                          시간이 입력되어 있지 않습니다.
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  )}
                </Grid>
              </td>
            ) : (
              ""
            )}

            <BasicTr>
              <td colSpan={5}>
                <HtmlContent
                  style={{ minHeight: "300px" }}
                  data={data.content ? data.content : "작성된 내용이 없습니다."}
                ></HtmlContent>
              </td>
            </BasicTr>
          </tbody>
        </BasicTable>
      </Grid>
    </>
  );
}

export default ApproveView;
