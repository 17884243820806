import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

export const TABLEICONS = {
  Add: forwardRef<SVGSVGElement>((props, ref) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef<SVGSVGElement>((props, ref) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef<SVGSVGElement>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef<SVGSVGElement>((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<SVGSVGElement>((props, ref) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef<SVGSVGElement>((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef<SVGSVGElement>((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef<SVGSVGElement>((props, ref) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export const ASMT_CLASSIFICATION = [
  { text: "전체", value: "" },
  { text: "개발", value: "개발" },
  { text: "영업", value: "영업" },
  { text: "품질", value: "품질" },
  { text: "관리", value: "관리" },
  { text: "생산", value: "생산" },
];
export const ASMT_EDIT_CLASSIFICATION = [
  { text: "개발", value: "개발" },
  { text: "영업", value: "영업" },
  { text: "품질", value: "품질" },
  { text: "관리", value: "관리" },
  { text: "생산", value: "생산" },
];
export const ASMT_STATUS = [
  { text: "전체", value: "" },
  { text: "등록대기", value: "등록대기" },
  { text: "진행", value: "진행" },
  { text: "완료", value: "완료" },
]; // 임시저장, 중단

export const ASMT_STATUS_ADMIN = [
  { text: "전체", value: "" },
  { text: "등록대기", value: "등록대기" },
  { text: "진행", value: "진행" },
  { text: "완료", value: "완료" },
  { text: "중단", value: "중단" },
];

export const ASMT_STATUS_GRID_ADMIN = ["진행", "완료", "중단"];

export const ASMT_STATUS_OWNED = [
  { text: "전체", value: "" },
  { text: "임시저장", value: "임시저장" },
  { text: "등록대기", value: "등록대기" },
  { text: "진행", value: "진행" },
  { text: "완료", value: "완료" },
  { text: "중단", value: "중단" },
];
export const SEARCH_NAME = [
  { text: "전체" },
  { text: "파트" },
  { text: "PM" },
  { text: "과제명" },
  { text: "멘토" },
];
export const SEARCH_NAME_INTERN = [
  { text: "전체" },
  { text: "파트" },
  { text: "과제명" },
  { text: "멘토" },
];
export const QNA_CLASSIFICATION = [
  { text: "기획" },
  { text: "일정" },
  { text: "기술" },
  { text: "기타" },
];
export const QNA_COMMENT = [
  { text: "질문", value: "질문" },
  { text: "답변", value: "답변" },
];

export const SearchYears = ["2022", "2023", "2024", "2025"];

export const ACTIONITEM_STATUS_CREATE = [
  { text: "임시저장", value: "임시저장" },
  { text: "등록대기", value: "등록대기" },
];

export const ACTIONITEM_STATUS = [
  { text: "임시저장", value: "임시저장" },
  { text: "등록대기", value: "등록대기" },
  { text: "진행", value: "진행" },
  { text: "완료", value: "완료" },
  { text: "보류", value: "보류" },
  { text: "중단", value: "중단" },
];

export const ACTIONITEM_STATUS_ALL = [
  { text: "임시저장", value: "임시저장" },
  { text: "등록대기", value: "등록대기" },
  { text: "진행", value: "진행" },
  { text: "완료", value: "완료" },
  { text: "보류", value: "보류" },
  { text: "중단", value: "중단" },
];

export const ACTIONITEM_PRIORITY = [
  { value: "매우높음", text: "매우높음" },
  { value: "높음", text: "높음" },
  { value: "보통", text: "보통" },
  { value: "낮음", text: "낮음" },
  { value: "매우낮음", text: "매우낮음" },
];
