import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/organization-units";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _OrganizationUnitsApi extends _BasicApi {
  getOrganizationUnitList() {
    return this.client.get(baseUri);
  }
}

const OrganizationUnitsApi = new _OrganizationUnitsApi(client);
export default OrganizationUnitsApi;
