import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DailyWorkRoutes } from "../../system/types/type";
import TitleDiv from "../Common/TitleDiv";

// mui
import { Grid } from "@mui/material";

// syncfusion
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import { CustomButton } from "../../styles/theme";
import { DailyApi } from "../../system/api/DailyApi";
import { ErrorHandler } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { dailyWork } from "../../system/types/dailyWork";

function DailyTable() {
  const user = useUserState();
  const history = useHistory();
  const [data, setData] = useState<dailyWork[]>([]);
  const datevalue = new Date();
  const calendarYear = datevalue.getFullYear();
  const calendarMonth =
    datevalue.getMonth() + 1 < 10
      ? "0" + (datevalue.getMonth() + 1)
      : datevalue.getMonth() + 1;
  const calendarDate =
    datevalue.getDate() < 10 ? "0" + datevalue.getDate() : datevalue.getDate();
  const today = calendarYear + "-" + calendarMonth + "-" + calendarDate;
  const dateValue: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    Number(calendarDate)
  );
  const [calendarday, setCalendarDay] = useState(today);

  useEffect(() => {
    DailyApi.getDailyWorkAll(calendarday)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [calendarday]);

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      history.push({
        pathname: `${DailyWorkRoutes.root}/detail/${selectedrecords[0].id}`,
      });
    }
  };

  let datepicker: any;

  function pickdate() {
    const calendarYear = datepicker.currentDate.getFullYear();
    const calendarMonth =
      datepicker.currentDate.getMonth() + 1 < 10
        ? "0" + (datepicker.currentDate.getMonth() + 1)
        : datepicker.currentDate.getMonth() + 1;
    const calendarDate =
      datepicker.currentDate.getDate() < 10
        ? "0" + datepicker.currentDate.getDate()
        : datepicker.currentDate.getDate();
    setCalendarDay(calendarYear + "-" + calendarMonth + "-" + calendarDate);
  }

  const pageSettings: PageSettingsModel = { pageSize: 10 };
  const sortSettings: SortSettingsModel = {
    columns: [{ field: "name", direction: "Ascending" }],
  };

  const gotoWrite = () => {
    history.push({
      pathname: `${DailyWorkRoutes.edit}`,
      state: calendarday,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title=" 일일업무 보고"></TitleDiv>
      </Grid>
      <Grid item xs={2}>
        <DatePickerComponent
          id="datepicker"
          value={dateValue}
          placeholder="날짜를 입력해주세요."
          format="yyyy-MM-dd"
          ref={(g) => (datepicker = g)}
          onChange={pickdate}
        />
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={2} sx={{ textAlign: "right" }}>
        {!user.roles.includes("Admin") && (
          <CustomButton iconCss="e-icons e-edit" onClick={gotoWrite}>
            등록
          </CustomButton>
        )}
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          ref={(g) => (grid = g)}
          allowSorting={true}
          sortSettings={sortSettings}
          rowSelected={rowSelected}
          allowGrouping={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="owner.department"
              headerText="소속"
              width="50"
            />
            <ColumnDirective field="owner.team" headerText="팀" width="50" />
            <ColumnDirective
              field="owner.jobPosition"
              headerText="직급"
              width="50"
            />
            <ColumnDirective field="owner.name" headerText="이름" width="50" />
            <ColumnDirective field="issue" headerText="이슈" width="200" />
            <ColumnDirective
              field="createdAt"
              headerText="최초등록일자"
              width="100"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Group]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default DailyTable;
