import client from "../ApiService";
import HttpClient from "../http-client";
import { Calendar, ReportDetail } from "../types/Report";

const baseUri: string = "/article/report";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ReportNoteApi extends _BasicApi {
  getReportList(parentId: number) {
    return this.client.get(`${baseUri}/all/${parentId}`);
  }
  createReportList(report: ReportDetail) {
    return this.client.post(`${baseUri}/create`, report);
  }
  getReportDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  updateReport(report: ReportDetail) {
    return this.client.put(`${baseUri}/update`, report);
  }
  deleteReport(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
  getCalendar(userEmail: string, Date: string) {
    return this.client.get(`/graph/calendar`, {
      params: {
        userEmail: userEmail,
        date: Date,
      },
    });
  }
  createCalendar(calendar: Calendar) {
    return this.client.post(`/graph/calendar`, calendar);
  }
}

const ReportNoteApi = new _ReportNoteApi(client);
export default ReportNoteApi;
