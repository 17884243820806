import client from "../ApiService";
import HttpClient from "../http-client";
import { AsmtDetail } from "../types/AsmtWork";

const baseUri: string = "/asmt/work";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AsmtWorkApi extends _BasicApi {
  GetAsmtworkDetail(asmtWorkId: number) {
    return this.client.get<AsmtDetail>(baseUri + "/full-timer/detail/" + asmtWorkId);
  }
  CreateAsmtworkDetail(fullTimerAsmtWork: AsmtDetail) {
    return this.client.post<AsmtDetail>(baseUri + "/full-timer/create", fullTimerAsmtWork);
  }
  UpdateAsmtworkDetail(fullTimerAsmtWork: AsmtDetail | undefined) {
    return this.client.put<AsmtDetail>(baseUri + "/full-timer/update", fullTimerAsmtWork);
  }
  DeleteAsmtwork(id: number) {
    return this.client.delete(baseUri + "/full-timer/delete/" + id);
  }
}

const AsmtWorkApi = new _AsmtWorkApi(client);
export default AsmtWorkApi;
