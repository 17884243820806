import { Grid, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PRIMARY } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import AsmtWorkApi from "../../../system/api/AsmtApi";
import { useUserState } from "../../../system/context/UserContext";
import { AsmtDetail } from "../../../system/types/AsmtWork";
import { InitAsmtDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes } from "../../../system/types/type";
import CommentTable from "../../Common/Comment/CommentTable";
import TitleDiv from "../../Common/TitleDiv";
import DevelopTable from "../../Note/Develop/DevelopTable";
import MeetingTable from "../../Note/Meeting/MeetingTable";
import ReportTable from "../../Note/Report/ReportTable";
import AsmtDetailInfo from "./AsmtDetailInfo";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface Params {
  id: string;
}

function AsmtView() {
  const { id } = useParams<Params>();
  const asmtId = parseInt(id);
  const history = useHistory();
  const user = useUserState();
  const [data, setData] = useState<AsmtDetail>(InitAsmtDetail);
  const [value, setValue] = useState(1);
  const [commentnumber, setCommentNumber] = useState(0);
  const Loading = useLoadingDispatch();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (asmtId) {
      Loading({ type: "LOADING" });
      AsmtWorkApi.GetAsmtworkDetail(asmtId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    } else {
      alert("잘못된 접근입니다.");
      history.push(AsmtWorkRoutes.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asmtId, commentnumber]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="과제업무 상세"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={value}
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: PRIMARY },
          }}
          onChange={handleChange}
          style={{ marginBottom: "10px" }}
        >
          <Tab label={<span>기획서</span>} value={1} />

          <Tab
            label={
              <Tooltip title="*개별적으로 진행하는 미팅에 대한 기록을 하시면 됩니다. 본 노트는 참여자들만 확인 가능합니다.">
                <span>미팅노트</span>
              </Tooltip>
            }
            value={2}
          />

          {/* {data.participants.findIndex((x) => x.name === user.name) !== -1 || user.roles.includes("Admin") ? (
            <Tab
              label={
                <Tooltip title="*기존 개발직무에서 사용하는 노트입니다. 본 노트는 참여자 및 관리자만 확인 가능합니다.">
                  <span>개발노트</span>
                </Tooltip>
              }
              value={3}
            />
          ) : null} */}
          {data.participants.findIndex((x) => x.name === user.name) !== -1 ||
          user.roles.includes("Admin") ? (
            <Tab label={<span>보고서</span>} value={4} />
          ) : null}
        </Tabs>
        {value === 1 && (
          <>
            <AsmtDetailInfo data={data} type={"View"}></AsmtDetailInfo>
            <CommentTable
              cType={"asmtWork"}
              data={data}
              setCommentNumber={setCommentNumber}
            />
          </>
        )}
        {value === 2 && (
          <MeetingTable
            parent={{
              parentId: data.id,
              parentTitle: data.title,
              workType: data.workType,
            }}
          ></MeetingTable>
        )}
        {value === 3 && (
          <DevelopTable
            admin={
              data.participants.findIndex((x) => x.name === user.name) === -1 &&
              user.roles.includes("Admin")
                ? true
                : false
            }
            parent={{
              parentId: data.id,
              parentTitle: data.title,
              workType: data.workType,
            }}
          ></DevelopTable>
        )}
        {value === 4 && (
          <ReportTable
            admin={
              data.participants.findIndex((x) => x.name === user.name) === -1 &&
              user.roles.includes("Admin")
                ? true
                : false
            }
            parent={{
              parentId: data.id,
              parentTitle: data.title,
              workType: data.workType,
              participant: data.participants,
            }}
          ></ReportTable>
        )}
      </Grid>
    </Grid>
  );
}

export default AsmtView;
