import { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRecoilValue } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";
const { Document, Page, pdfjs } = require("react-pdf");
pdfjs.GlobalWorkerOptions.workerSrc =
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const pdfFile = "manual.pdf";

function Guide() {
  const open = useRecoilValue(leftDrawerOpenState);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.1);
  const [pageNumber, setPageNumber] = useState(1);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
    setScale((innerWidth + 820) / 1920);
  });

  useEffect(() => {
    window.addEventListener("keydown", keyDown);
    return () => window.removeEventListener("keydown", keyDown);
  });
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (numPages % 2 === 0) {
      if (pageNumber + 1 < numPages) {
        setPageNumber(pageNumber + 2); // 다음 두 페이지를 한 번에 넘김
      }
    } else {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 2); // 다음 두 페이지를 한 번에 넘김
      }
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 2) {
      setPageNumber(pageNumber - 2); // 이전 두 페이지를 한 번에 넘김
    } else if (pageNumber === 2) {
      setPageNumber(1); // 첫 번째 페이지로 돌아감
    }
  };
  const keyDown = (event) => {
    const key = event.key;
    switch (key) {
      case "ArrowRight":
        handleNextPage();
        break;
      case "ArrowLeft":
        handlePrevPage();
        break;
      default:
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      style={{ marginLeft: open ? "-1%" : "8%" }}
      justifyContent="center-between"
      alignItems="flex-start"
      minWidth={500}
    >
      {/* <Grid item xs={12}>
      <TitleDiv title="과제업무 매뉴얼"></TitleDiv>
    </Grid> */}

      {/* 버튼 */}
      {/* pdf 내용(page) */}
      <IconButton
        style={{
          zIndex: 10,
          position: "fixed",
          top: "50%",
          left: open ? "13%" : "1%",
        }}
        onClick={handlePrevPage}
        disabled={pageNumber <= 2}
        size="small"
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Document
        file={pdfFile} // PDF 파일 경로
        onLoadSuccess={onDocumentLoadSuccess}
        style={{ display: "flex !important" }}
      >
        <Page renderTextLayer={false} scale={scale} pageNumber={pageNumber} />
        <div
          style={{
            display: pageNumber >= numPages ? "none" : "inline",
            marginBottom: "1%",
            marginRight: "1%",
            marginLeft: "1%",
            borderLeft: "1px solid #E0E0E0",
          }}
        ></div>
        {pageNumber + 1 <= numPages && (
          <Page
            scale={scale}
            renderTextLayer={false}
            pageNumber={pageNumber + 1}
          />
        )}
      </Document>

      <IconButton
        style={{ zIndex: 10, position: "fixed", top: "50%", right: "1%" }}
        onClick={handleNextPage}
        disabled={
          numPages % 2 === 0
            ? pageNumber + 1 >= numPages
            : pageNumber >= numPages
        }
        size="small"
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Grid>
  );
}
export default Guide;
