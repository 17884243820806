import { Grid, Typography } from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BasicTr, CustomButton, CustomButtonRed } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { InternApi } from "../../../system/api/InternApi";
import FileApi from "../../../system/api/fileApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useUserState } from "../../../system/context/UserContext";
import { InternWorkDetail } from "../../../system/types/InternWork";
import { InitInternWorkDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import SelecMentorEmployees from "../../Common/SelectMentorEmployee";
import TitleDiv from "../../Common/TitleDiv";
import { defaultInternValue } from "../../Common/Editor/defaultInternValue";

interface Params {
  id: string;
}

function validation(data: InternWorkDetail) {
  if (
    data.participants.length < 1 ||
    data.participants.filter((x) => x.mentor).length === 0
  ) {
    alert("1명 이상의 멘토가 지정되어야 합니다.");
    return false;
  } else if (data.participants.filter((x) => !x.mentor).length < 1) {
    alert("참여자에는 인턴이 반드시 포함되어야 합니다.");
    return false;
  }
  return true;
}

function InternAdminEdit() {
  const { id } = useParams<Params>();
  const internId = parseInt(id);
  const history = useHistory();
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [data, setData] = useState<InternWorkDetail>(InitInternWorkDetail);
  const [started, setStarted] = useState<boolean>(false);

  useEffect(() => {
    if (internId) {
      Loading({ type: "LOADING" });
      InternApi.getInternWorkDetail(internId)
        .then((res) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(res.data.content, "text/html");
          const tableElement = doc.querySelector(
            'table[name="namo-editor-intern-table-class"]'
          );

          if (tableElement) {
            setData({ ...res.data, setInfo: true });
          } else {
            setData({
              ...res.data,
              setInfo: true,
              content: defaultInternValue + res.data.content,
            });
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internId, user.name]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
  };

  const onClickChip = (id: number) => {
    const newList = data.participants;
    var emp = data.participants.findIndex((x) => x.id === id);
    var state = newList[emp].mentor;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], mentor: !state };
    }
    setData({ ...data, participants: newList });
  };

  const onSave = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    if (validation(data) && refContent.current) {
      const editor = refContent.current.editorRef;
      Loading({ type: "LOADING" });
      InternApi.updateInternWork({
        ...data,
        content: editor.GetBodyValue() || "",
      })
        .then((res) => {
          alert("과제가 수정되었습니다.");
          history.push(`${AsmtWorkRoutes.intern}/detail/${res.data.id}`);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
          alert("변경사항을 저장하는 중 오류가 발생했습니다.");
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  };

  const onDelete = () => {
    if (window.confirm("삭제 후 복구할 수 없습니다. 정말 삭제하시겠습니까?")) {
      Loading({ type: "LOADING" });
      InternApi.deleteInternWork(data.id)
        .then(() => {
          alert("과제가 삭제되었습니다.");
          history.push(AsmtWorkRoutes.internList);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  };

  const onCancel = () => {
    history.push(`${AsmtWorkRoutes.intern}/detail/${data.id}`);
  };

  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const params = {
    Width: "100%",
    Height: 800,
  };

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  const boxStyle = {
    padding: "10px",
    border: "1px solid #DCDFE3",
    height: "48px",
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="인턴 과제 수정(관리자)"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            과제명
          </Grid>
          <Grid item xs={12}>
            <TextBoxComponent
              value={data.title}
              placeholder="과제명을 입력해 주세요."
              name="title"
              onChange={onChange}
              style={{ height: "48px" }}
              cssClass="e-outline"
            ></TextBoxComponent>
          </Grid>
          <Grid item xs={12}>
            참여자
          </Grid>
          <Grid item xs={12}>
            <SelecMentorEmployees
              list={data.participants}
              onChange={onChangeEmp}
              onClickChip={onClickChip}
            ></SelecMentorEmployees>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              등록일
            </Grid>
            <Grid item xs={12} style={boxStyle}>
              {data.createdAt
                ? new Date(data.createdAt).toLocaleDateString()
                : "-"}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              시작일
            </Grid>
            <Grid item xs={12} style={boxStyle}>
              {data.startDate
                ? new Date(data.startDate).toLocaleDateString()
                : "-"}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item xs={12}>
              진행상태
            </Grid>

            <Grid item xs={12}>
              <DropDownListComponent
                dataSource={["임시저장", "등록대기", "진행", "완료", "중단"]}
                value={data.status}
                onChange={onChange}
                name="status"
                cssClass="e-outline"
                style={{ height: "48px" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item xs={12}>
              세부상태
            </Grid>
            <Grid item xs={12}>
              <TextBoxComponent
                value={data.subStatus}
                placeholder="세부상태를 입력해 주세요."
                name="subStatus"
                onChange={onChange}
                style={{ height: "48px" }}
                cssClass="e-outline"
              ></TextBoxComponent>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid item xs={12}>
            {data.setInfo && (
              <CrossEditor
                ref={refContent}
                name="InBodyAssignmentWork"
                params={params}
                value={data.content}
                onLoaded={onLoaded}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <BasicTr>
              <th>첨부파일</th>
              <td colSpan={5}>
                {data.attachmentFiles
                  ? data.attachmentFiles.map((file, idx) => {
                      return (
                        <div key={idx} style={{ cursor: "pointer" }}>
                          <Typography
                            variant="body2"
                            onClick={() =>
                              downloadFile(file.filename, file.filePath)
                            }
                          >
                            {file.filename}
                          </Typography>
                        </div>
                      );
                    })
                  : "-"}
              </td>
            </BasicTr>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: "left", marginTop: "10px" }}>
        <CustomButton iconCss="e-icons e-chevron-left" onClick={onCancel}>
          취소
        </CustomButton>
      </Grid>
      <Grid item xs={10} sx={{ textAlign: "right", marginTop: "10px" }}>
        <CustomButton
          iconCss="e-icons e-delete-1"
          onClick={onDelete}
          style={{ marginRight: "10px" }}
        >
          삭제
        </CustomButton>
        <CustomButtonRed iconCss="e-icons e-check" onClick={onSave}>
          저장
        </CustomButtonRed>
      </Grid>
    </Grid>
  );
}

export default InternAdminEdit;
