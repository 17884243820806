import { Grid } from "@mui/material";
import {
  Filter,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import { useEffect, useState } from "react";
import { CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import { ActionItemArticleInfo } from "../../../system/types/ActionItem";
import AlertModal from "../../Common/AlertModal";

interface AIMeetingTableProps {
  id: number;
  articleType: string;
  setNoteId: (noteId: number | null) => void;
  setType: (type: string) => void;
}

function AIMeetingTable({
  id,
  articleType,
  setNoteId,
  setType,
}: AIMeetingTableProps) {
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  const [data, setData] = useState<ActionItemArticleInfo[]>([]);

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      setNoteId(selectedrecords[0].id);
      setType("detail");
    }
  };

  useEffect(() => {
    ActionItemsApi.getActionItemNoteList(id, articleType)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [id, articleType]);

  const onWriteReport = () => {
    setType("edit");
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <CustomButton onClick={onWriteReport} iconCss="e-icons e-plus">
          보고서 작성
        </CustomButton>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnDirective
            field="createdAt"
            headerText="작성일"
            textAlign="Center"
            width="200"
          />
          <ColumnsDirective>
            <ColumnDirective
              field="title"
              headerText="보고서명"
              headerTextAlign="Center"
              width="500"
            />

            <ColumnDirective
              field="createBy"
              headerText="작성자"
              textAlign="Center"
              width="200"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default AIMeetingTable;
