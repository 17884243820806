import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemText,
  CSSObject,
  styled,
  Theme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { red } from "@mui/material/colors";
import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../styles/theme";
import { useUserState } from "../../system/context/UserContext";
import {
  AdminRoutes,
  AIRoutes,
  AsmtWorkRoutes,
  GuideRoutes,
  NoticeRoutes,
} from "../../system/types/type";
import { useRecoilState } from "recoil";
import {
  pageState,
  searchDataState,
  searchDetailState,
  yearState,
} from "../../system/atoms";
import { InitAsmtSearch, InitSearch } from "../../system/types/initObject";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)})`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  weight: "bold",
  textAlign: "center",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#3c3c3b",
  "& a:hover": {
    color: "#ffffff",
    backgroundColor: "#545453",
    filter: "brightness(1.2)",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiListItemText-primary	": {
    color: "#fff",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  marginTop: "80px",
}));

const NavList = styled(List)<{ component?: React.ElementType }>({
  "& 	.MuiListItemText-root": {
    paddingLeft: theme.spacing(1.1),
  },

  "& .MuiList-root": {
    backgroundColor: "#545453",
  },
  "& .MuiList-root .MuiListItem-root": {
    paddingLeft: theme.spacing(3),
  },
  "& .MuiListItem-root .MuiListItem-gutters .active": {
    backgroundColor: red,
  },
});

interface PersistentDrawerLeftProps {
  open: boolean;
}

export default function PersistentDrawerLeft({
  open,
}: PersistentDrawerLeftProps) {
  const user = useUserState();
  const [asmt, setAsmt] = React.useState<boolean>(false);
  const [my, setMy] = React.useState<boolean>(false);
  const [admin, setAdmin] = React.useState<boolean>(false);
  const [guide, setGuide] = React.useState<boolean>(false);
  const [, setSearchData] = useRecoilState(searchDataState);
  const [, setSearchDetail] = useRecoilState(searchDetailState);
  const [, setYear] = useRecoilState(yearState);
  const [, setPage] = useRecoilState(pageState);

  const handleClick = (name: string) => {
    if (name === "asmt") {
      setAsmt(!asmt);
    } else if (name === "my") {
      setMy(!my);
    } else if (name === "admin") {
      setAdmin(!admin);
    } else if (name === "guide") {
      setGuide(!guide);
    }
  };

  function resetState() {
    setSearchData(InitAsmtSearch);
    setSearchDetail(InitSearch);
    setYear(new Date().getFullYear().toString());
    setPage(0);
  }

  return (
    <>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#3c3c3b",
          },
        }}
      >
        <DrawerHeader>
          <Grid
            container
            direction="row"
            alignContent="space-between"
            style={{ height: "90vh" }}
          >
            <Grid item xs={12}>
              <NavList>
                <ListItem
                  component={Link}
                  to={NoticeRoutes.root}
                  onClick={resetState}
                >
                  <ListItemText primary="공지사항" />
                </ListItem>
                <ListItem
                  component={Link}
                  to={AIRoutes.root}
                  onClick={resetState}
                >
                  <ListItemText primary="A/I 조회" />
                </ListItem>
                <ListItem></ListItem>
                <ListItem
                  onClick={() => handleClick("asmt")}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemText primary="과제 업무 조회" />
                  {asmt ? (
                    <ExpandLess sx={{ color: "#dddddd" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#dddddd" }} />
                  )}
                </ListItem>
                <Collapse in={asmt} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      component={Link}
                      to={AsmtWorkRoutes.root}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="과제 업무"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                    <ListItem
                      component={Link}
                      to={AsmtWorkRoutes.internList}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="인턴 과제 업무"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                    {user.jobTitle === "그룹장" ? (
                      <ListItem
                        component={Link}
                        to={AsmtWorkRoutes.members}
                        onClick={resetState}
                      >
                        <ListItemText
                          secondary="우리 그룹 과제 업무"
                          secondaryTypographyProps={{
                            fontWeight: "100",
                            color: "#dddddd",
                            fontSize: "1rem",
                          }}
                        />
                      </ListItem>
                    ) : user.jobTitle === "파트장" ? (
                      <ListItem
                        component={Link}
                        to={AsmtWorkRoutes.members}
                        onClick={resetState}
                      >
                        <ListItemText
                          secondary="우리 파트 과제 업무"
                          secondaryTypographyProps={{
                            fontWeight: "100",
                            color: "#dddddd",
                            fontSize: "1rem",
                          }}
                        />
                      </ListItem>
                    ) : (
                      <></>
                    )}
                    {user.jobTitle === "팀장" ? (
                      <ListItem
                        component={Link}
                        to={AsmtWorkRoutes.members}
                        onClick={resetState}
                      >
                        <ListItemText
                          secondary="우리 팀 과제 업무"
                          secondaryTypographyProps={{
                            fontWeight: "100",
                            color: "#dddddd",
                            fontSize: "1rem",
                          }}
                        />
                      </ListItem>
                    ) : (
                      <></>
                    )}
                  </List>
                </Collapse>

                <ListItem
                  onClick={() => handleClick("my")}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemText primary="나의 과제 업무" />
                  {my ? (
                    <ExpandLess sx={{ color: "#dddddd" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#dddddd" }} />
                  )}
                </ListItem>
                <Collapse in={my} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      component={Link}
                      to={AsmtWorkRoutes.owned}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="과제 등록 및 조회"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                    <ListItem
                      component={Link}
                      to={AsmtWorkRoutes.intern}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="인턴 과제 등록 및 조회"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem
                  onClick={() => handleClick("guide")}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemText primary="과제 업무 가이드" />
                  {guide ? (
                    <ExpandLess sx={{ color: "#dddddd" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#dddddd" }} />
                  )}
                </ListItem>
                <Collapse in={guide} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      component={Link}
                      to={GuideRoutes.guide}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="과제 업무 메뉴얼"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                    <ListItem
                      component={Link}
                      to={GuideRoutes.qnaRoot}
                      onClick={resetState}
                    >
                      <ListItemText
                        secondary="문의하기(QnA)"
                        secondaryTypographyProps={{
                          fontWeight: "100",
                          color: "#dddddd",
                          fontSize: "1rem",
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>

                {/* <ListItem component={Link} to={DailyWorkRoutes.root}>
                      <ListItemText primary="일일업무 보고(삭제?)" />
                    </ListItem> */}

                {(user.roles.includes("Admin") ||
                  user.roles.includes("ActionItem.admin")) && (
                  <>
                    <ListItem
                      onClick={() => handleClick("admin")}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItemText primary="관리자 페이지" />
                      {admin ? (
                        <ExpandLess sx={{ color: "#dddddd" }} />
                      ) : (
                        <ExpandMore sx={{ color: "#dddddd" }} />
                      )}
                    </ListItem>
                    <Collapse in={admin} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          component={Link}
                          to={AdminRoutes.asmt}
                          onClick={resetState}
                        >
                          <ListItemText
                            secondary="Note 관리"
                            secondaryTypographyProps={{
                              fontWeight: "100",
                              color: "#dddddd",
                              fontSize: "1rem",
                            }}
                          />
                        </ListItem>
                        <ListItem component={Link} to={AdminRoutes.statistics}>
                          <ListItemText
                            secondary="임직원 과제 참여 현황"
                            secondaryTypographyProps={{
                              fontWeight: "100",
                              color: "#dddddd",
                              fontSize: "1rem",
                            }}
                          />
                        </ListItem>
                        <ListItem component={Link} to={AdminRoutes.role}>
                          <ListItemText
                            secondary="관리자 권한 설정"
                            secondaryTypographyProps={{
                              fontWeight: "100",
                              color: "#dddddd",
                              fontSize: "1rem",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}
              </NavList>
            </Grid>
          </Grid>
        </DrawerHeader>
      </Drawer>
    </>
  );
}
