//React
import { useUserState } from "../../system/context/UserContext";

//MUI
import { IconButton, Toolbar, Typography, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

//MUI icon
import MenuIcon from "@mui/icons-material/Menu";

//user component
import { NavLink } from "react-router-dom";
import PersistentDrawerLeft from "../../components/Common/PersistentDrawerLeft";
import { HeaderStyle, MenuButton, NavProfile, Title } from "../../styles/theme";
import { useRecoilState } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header() {
  const user = useUserState();
  const [open, setOpen] = useRecoilState(leftDrawerOpenState);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <HeaderStyle>
      <AppBar color="inherit" position="fixed" open={open}>
        <Toolbar style={{ minWidth: "1050px" }}>
          <MenuButton>
            <IconButton
              edge="start"
              onClick={handleDrawerOpen}
              color="inherit"
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </MenuButton>
          <NavLink to="/">
            <img src="/images/inbody_logo.png" width="100px" alt="title"></img>
          </NavLink>
          <Title>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              과제업무
            </Typography>
          </Title>

          <NavProfile>
            <Typography variant="body2">{user.gwMail}</Typography>
            <Typography variant="body2">{`${user.name}`}</Typography>
          </NavProfile>

          {/* <div>
            <IconButton aria-label="show 17 new notifications" color="inherit" onClick={handlePopover} size="large">
              <Badge overlap="rectangular" badgeContent={user.notifications.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Popover
              id={notiId}
              open={notiOpen}
              anchorEl={popoverAnchorEl}
              onClose={notiHandleColse}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div style={{ minWidth: "300px", margin: "10px", textAlign: "center" }}>
                {user.notifications.map((noti) => (
                  <div style={{ marginBottom: "10px" }} key={noti.id.toString()}>
                    {noti.message}
                    <br />
                    <span style={{ textAlign: "center", fontSize: "12px" }}>
                      {noti.createdAt}, by {noti.createBy}
                    </span>
                    <br />
                    <Divider />
                  </div>
                ))}
              </div>

              <div style={{ textAlign: "end" }}>
                <Button onClick={onReadAllNoti} style={{ fontSize: "12px", color: PRIMARY }} size="small">
                  모두 읽음
                </Button>
              </div>
            </Popover>
          </div> */}
        </Toolbar>
      </AppBar>
      <div>
        <PersistentDrawerLeft open={open}></PersistentDrawerLeft>
      </div>
    </HeaderStyle>
  );
}

export default Header;
