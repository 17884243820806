import client from "../ApiService";
import HttpClient from "../http-client";
import { QnaContent } from "../types/type";

const baseUri: string = "/article/qna";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _QnaApi extends _BasicApi {
  getQnaAll(page: number, size: number, sort: string) {
    return this.client.get(`${baseUri}/all`, { params: { page: page, size: size, sort: sort } });
  }
  getQnaDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  createQna(qna: QnaContent) {
    return this.client.post(`${baseUri}/create`, qna);
  }
  updateQna(qna: QnaContent) {
    return this.client.put(`${baseUri}/update`, qna);
  }
  deleteQna(id: number) {
    return this.client.delete(`${baseUri}/delete`, { params: { qnaId: id } });
  }
}

export const QnaApi = new _QnaApi(client);
