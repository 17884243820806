import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import {
  DropDownTreeComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import {
  EditDropDownListComponentBox,
  AITableContent,
  AITableTitle,
  StyledTextarea,
  TableFixedHeight,
  CustomButtonRed,
} from "../../styles/theme";
import { CustomText } from "../../styles/CustomText";
import { useEffect, useState } from "react";
import { Gray500 } from "../../styles/Colors";
import {
  ActionItemInfo,
  BasicInfo,
  ManagerInfo,
  OrganizationInfo,
  TriggeringReportInfo,
} from "../../system/types/ActionItem";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ACTIONITEM_PRIORITY,
  ACTIONITEM_STATUS,
  ACTIONITEM_STATUS_CREATE,
} from "../../system/Constants";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import moment from "moment";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import SelectManager from "./SelectManager";
import AlertModal from "../Common/AlertModal";
import { Query } from "@syncfusion/ej2-data";

interface ActionItemEditProps {
  data: ActionItemInfo;
  setData: (data: ActionItemInfo) => void;
  type: string;
  teamData: OrganizationInfo[];
  selectedReport: TriggeringReportInfo;
  setSelectedReport: (selectedReport: TriggeringReportInfo) => void;
}
function ActionItemEdit({
  data,
  setData,
  type,
  teamData,
  selectedReport,
  setSelectedReport,
}: ActionItemEditProps) {
  const [categoryData, setCategoryData] = useState<BasicInfo[]>([]);

  const onChange = (args: any) => {
    const value = args.target.value;
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: value,
    });
  };
  const onChangeCheck = (args: any) => {
    const value = args.target.value;
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]:
        value === "true" ? true : value === "false" ? false : value,
    });
  };
  const onChangeReportTitle = (args: any) => {
    const value = args.target.value;
    setSelectedReport({
      ...selectedReport,

      [(args.target as HTMLInputElement).name]: value,
    });
  };

  const onChangeReportDate = (args: any) => {
    setSelectedReport({
      ...selectedReport,

      [(args.target as HTMLInputElement).name]: moment(
        args.target.value
      ).format("YYYY-MM-DD" + " 00:00:00"),
    });
  };

  const onChangeDate = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: moment(
        args.target.value
      ).format("YYYY-MM-DD"),
    });
  };
  useEffect(() => {
    ActionItemsApi.getAICategoryList()
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChangeManager = (userList: ManagerInfo[]) => {
    if (userList) {
      setData({ ...data, managers: userList });
    }
  };

  const onClickChip = (id: number) => {
    const newList = data.managers;
    var emp = data.managers.findIndex((x) => x.manager.id === id);
    var state = newList[emp].isPrimaryManager;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], isPrimaryManager: !state };
    }
    setData({ ...data, managers: newList });
  };
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setData({
      ...data,
      categoryIds:
        typeof value === "string" ? value.split(",").map(Number) : value,
    });
  };

  const renderSelectedNames = (selected: number[]) => {
    const selectedNames = categoryData
      .filter((item) => selected.includes(item.id))
      .map((item) => item.name)
      .join(", "); // 이름을 콤마로 구분하여 표시
    return selectedNames;
  };

  // 시작 보고
  const [reportData, setReportData] = useState<TriggeringReportInfo[]>([]);
  function refreshData() {
    ActionItemsApi.getTriggeringReportsList()
      .then((res) => {
        const sortedData = res.data.sort(
          (a: { reportDate: string }, b: { reportDate: string }) =>
            new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
        );

        // 정렬된 데이터를 setReportData에 설정
        setReportData(sortedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshData();
  }, []);

  const onChangeReport = (args: any) => {
    const target = args.target.value;

    if (!target) {
      console.error("Target is null");
      return;
    }
    setData({ ...data, triggeringReportId: target });
    const foundReport = reportData.find((d) => d.id === target);
    if (foundReport) {
      setSelectedReport(foundReport);
    }
  };

  const dataFields: Object = {
    text: "title",
    value: "id",
  };
  const itemTemplate = (dt: TriggeringReportInfo) => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item>{moment(dt.reportDate).format("YYYY-MM-DD")}</Grid>
        {/* <Grid item>{dt.organizationUnit?.name}</Grid> */}
        <Grid item xs={12}>
          {dt.title}
        </Grid>
      </Grid>
    );
  };

  const valueTemplate = (dt: TriggeringReportInfo) => {
    return (
      <CustomText
        type="subtitle"
        bold400
        style={{ marginTop: "7px", paddingLeft: "10px", fontSize: "14px" }}
      >
        {dt.title}
      </CustomText>
    );
  };

  const onClearItem = () => {
    setData({ ...data, triggeringReportId: null });
  };

  let fields: Object = {
    dataSource: teamData,
    value: "id",
    text: "name",
    child: "children",
  };

  const levelZeroIds = teamData
    .filter((item) => item.level === 0)
    .map((item) => item.id);

  const onChangeTeam = (args: any) => {
    if (args.value && args.value.length > 0 && args.value[0]) {
      const checkIdExists = (id: number) => levelZeroIds.includes(id);

      if (checkIdExists(Number(args.value[0]))) {
        AlertModal("msg", "최상위 조직은 선택하실 수 없습니다.");
        setData({ ...data, organizationUnitId: null });
      } else {
        setData({
          ...data,
          organizationUnitId: Number(args.value[0]),
        });
      }
    }
  };

  return (
    <Grid container item xs={12} mt="20px">
      {/* 시작 보고 */}

      {type === "생성" && (
        <>
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="5px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                시작보고
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <EditDropDownListComponentBox
                cssClass="e-outline"
                name="status"
                onChange={onChangeReport}
                value={data.triggeringReportId}
                dataSource={reportData}
                fields={dataFields}
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                placeholder="시작보고를 선택해 주세요."
              />
            </AITableContent>
          </TableFixedHeight>
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="5px"
            pl="10px"
          >
            <CustomButtonRed iconCss="e-icons e-check" onClick={onClearItem}>
              {"시작보고 초기화"}
            </CustomButtonRed>
          </TableFixedHeight>

          {!data.triggeringReportId && (
            <TableFixedHeight
              container
              item
              xs={12}
              alignItems="center"
              mb="3px"
            >
              <AITableTitle
                item
                xs={2}
                container
                justifyContent="right"
                alignItems="center"
              >
                <CustomText type="subtitle" lineSpace color={Gray500}>
                  시작보고명
                </CustomText>
              </AITableTitle>
              <AITableContent
                item
                xs={10}
                pl="20px"
                container
                alignItems="center"
              >
                {data.triggeringReportId ? (
                  <CustomText type="body" marginLeft="10px">
                    {selectedReport.title}
                  </CustomText>
                ) : (
                  <TextBoxComponent
                    placeholder="시작보고 이름을 입력해주세요."
                    cssClass="e-outline"
                    name="title"
                    onChange={onChangeReportTitle}
                    value={selectedReport.title}
                  ></TextBoxComponent>
                )}
              </AITableContent>
            </TableFixedHeight>
          )}
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="3px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                시작보고일
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              {data.triggeringReportId ? (
                <CustomText type="body" marginLeft="10px">
                  {moment(selectedReport.reportDate).format("YYYY-MM-DD")}
                </CustomText>
              ) : (
                <DatePickerComponent
                  placeholder="시작보고일을 입력해 주세요."
                  name="reportDate"
                  format="yyyy-MM-dd"
                  value={
                    selectedReport?.reportDate
                      ? new Date(selectedReport?.reportDate)
                      : undefined
                  }
                  onChange={onChangeReportDate}
                  cssClass="e-outline"
                  style={{ height: "38px" }}
                />
              )}
            </AITableContent>
          </TableFixedHeight>

          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="7px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                상태
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <EditDropDownListComponentBox
                cssClass="e-outline"
                name="status"
                onChange={onChange}
                value={data.status}
                dataSource={
                  type === "생성" ? ACTIONITEM_STATUS_CREATE : ACTIONITEM_STATUS
                }
                placeholder="상태를 선택해 주세요."
              />
            </AITableContent>
          </TableFixedHeight>
        </>
      )}
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="7px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            카테고리
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container>
          <FormControl sx={{ width: 300 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={data.categoryIds}
              onChange={handleChange}
              renderValue={renderSelectedNames}
              MenuProps={MenuProps}
              style={{ height: "41px", padding: "0px", fontSize: "14px" }}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e3165b", // 포커스 상태의 테두리 색상
                },
              }}
            >
              {categoryData.map((dt: BasicInfo, index: number) => (
                <MenuItem key={index} value={dt.id}>
                  <Checkbox checked={data.categoryIds.includes(dt.id)} />
                  <ListItemText
                    primary={dt.name}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="7px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            작성부서
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container>
          <DropDownTreeComponentBox
            cssClass="e-outline"
            change={onChangeTeam}
            fields={fields}
            changeOnBlur={false}
            placeholder="작성부서를 선택해 주세요."
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="3px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            목표 완료일
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <DatePickerComponent
            placeholder="목표 완료일을 입력해 주세요."
            name="dueDate"
            format="yyyy-MM-dd"
            value={data.dueDate ? new Date(data.dueDate) : undefined}
            onChange={onChangeDate}
            cssClass="e-outline"
            style={{ height: "38px" }}
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="3px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            비밀글
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <RadioGroup
            row
            onChange={onChangeCheck}
            value={data.isSecret}
            name="isSecret"
          >
            <FormControlLabel
              value={true}
              control={<Radio color="default" />}
              label="설정"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="default" />}
              label="설정 안 함"
            />
          </RadioGroup>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="7px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            우선순위
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <EditDropDownListComponentBox
            cssClass="e-outline"
            name="priority"
            onChange={onChange}
            value={data.priority}
            dataSource={ACTIONITEM_PRIORITY}
            placeholder="우선순위를 선택해 주세요."
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="7px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            긴급
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <RadioGroup
            row
            onChange={onChange}
            value={data.isUrgent}
            name="isUrgent"
          >
            <FormControlLabel
              value={true}
              control={<Radio color="default" />}
              label="설정"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="default" />}
              label="설정 안 함"
            />
          </RadioGroup>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} alignItems="center" mb="5px">
        <AITableTitle
          item
          xs={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            제목
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={10} pl="20px" container alignItems="center">
          <TextBoxComponent
            placeholder="제목을 입력해주세요."
            cssClass="e-outline"
            name="title"
            onChange={onChange}
            value={data.title}
          ></TextBoxComponent>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} alignItems="center" mb="5px">
        <AITableTitle
          item
          xs={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            내용
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={10} pl="20px" container alignItems="center">
          <StyledTextarea
            value={data.content}
            onChange={onChange}
            placeholder="내용을 입력해주세요."
            name="content"
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        alignItems="center"
        margin="5px 0px 8px 0px"
      >
        <AITableTitle
          item
          xs={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            담당자
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={10} pl="20px" container alignItems="center">
          <SelectManager
            list={data.managers}
            onChange={onChangeManager}
            onClickChip={onClickChip}
          />
        </AITableContent>
      </TableFixedHeight>
    </Grid>
  );
}

export default ActionItemEdit;
const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 250,
    },
  },
};

const DropDownTreeComponentBox = styled(DropDownTreeComponent)(() => ({
  width: "100%",
  height: "38px",
  fontSize: "14px",
}));
