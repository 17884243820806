import React, { ReactNode } from "react";
import { ContentWrapper, ContentShift, ContentBody } from "../../styles/theme";
import { useRecoilValue } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";
interface ContentProps {
  children: ReactNode;

}

function Content({ children }: ContentProps) {
  const open = useRecoilValue(leftDrawerOpenState);
  if (open) {
    return (
      <>
        <ContentWrapper>
          <ContentBody>{children}</ContentBody>
        </ContentWrapper>
      </>
    );
  } else {
  }
  return (
    <>
      <ContentShift>
        <ContentBody>{children}</ContentBody>
      </ContentShift>
    </>
  );
}

export default Content;
