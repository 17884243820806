import { createContext, Dispatch, useContext, useReducer } from "react";
import { AuthorityUser } from "../types/interface";
import { InitUser } from "../types/initObject";

const UserStateContext = createContext<AuthorityUser | undefined>(undefined);

type Action =
  | { type: "LOGIN"; item: AuthorityUser }
  | { type: "CHANGE_AUTH"; item: AuthorityUser }
  | { type: "LOGOUT" };

type UserDispatch = Dispatch<Action>;
const UserDispatchContext = createContext<UserDispatch | undefined>(undefined);

function UserReducer(state: AuthorityUser, action: Action): AuthorityUser {
  switch (action.type) {
    case "LOGIN":
      return {
        ...action.item,
      };
    case "CHANGE_AUTH":
      return {
        ...action.item,
      };
    case "LOGOUT": {
      return InitUser;
    }
  }
}

export function UserContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(UserReducer, InitUser);
  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
}

//custom hook
export function useUserState() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error("UserStateProvider not found");
  return state;
}

export function useUserDispatch() {
  const dispatch = useContext(UserDispatchContext);
  if (!dispatch) throw new Error("UserDispatchProvider not found");
  return dispatch;
}
