import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledBodyNoPadding,
  StyledBodyNoPaddingNumber,
  StyledHead1,
  StyledHead2,
} from "../../styles/theme";
import { ErrorHandler } from "../../system/ApiService";
import StatisticsApi from "../../system/api/StatisticsApi";
import { AggPart } from "../../system/types/Statistics";
import InfoIcon from "@mui/icons-material/Info";
interface PartStatusProps {
  year: string;
}

function PartStatus({ year }: PartStatusProps) {
  const [data, setData] = useState<AggPart[]>([]);

  useEffect(() => {
    StatisticsApi.GetPartStatistics(year)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [year]);

  const checkDecimal = (item: number) => {
    if (item % 1 === 0) {
      return item + ".0";
    } else {
      return item;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          파트별 과제 현황 (인당 과제수)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center" colSpan={2}>
                  소속
                </StyledHead1>
                <StyledHead1 align="center" colSpan={4}>
                  참여현황
                </StyledHead1>
                <StyledHead1 align="center" colSpan={3}>
                  성과급
                </StyledHead1>
              </TableRow>
              <TableRow>
                <StyledHead2 align="center">파트</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">과제수(개)</StyledHead2>
                <StyledHead2 align="center">인당건수(개)</StyledHead2>
                <StyledHead2 align="center">참여자수(명)</StyledHead2>
                <StyledHead2 align="center">참여율(%)</StyledHead2>
                <StyledHead2 align="center">
                  인원수(명)
                  <Tooltip title="퇴사자 포함" placement="right">
                    <InfoIcon
                      fontSize="small"
                      color="action"
                      sx={{ marginTop: "-3px", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </StyledHead2>
                <StyledHead2 align="center">금액(원)</StyledHead2>
                <StyledHead2 align="center">평균(원)</StyledHead2>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <StyledBodyNoPadding align="center">
                      {item.department}
                    </StyledBodyNoPadding>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.partMembers}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.works}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {" "}
                      {checkDecimal(item.perPerson)}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.participants}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {" "}
                      {checkDecimal(item.participantsRatio)}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.actualParticipants}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.totalMeritPay
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </StyledBodyNoPaddingNumber>
                    <StyledBodyNoPaddingNumber align="right">
                      {item.avgMeritPay
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </StyledBodyNoPaddingNumber>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default PartStatus;
