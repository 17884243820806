import { Grid } from "@mui/material";
import { useState } from "react";
import AIMeetingView from "./AIMeetingView";
import AIMeetingTable from "./AIMeetingTable";
import AIMeetingEdit from "./AIMeetingEdit";

interface AIMeetingNoteProps {
  id: number;
}

function AIMeetingNote({ id }: AIMeetingNoteProps) {
  // table, detail, edit
  const [type, setType] = useState("table");
  const [noteId, setNoteId] = useState<number | null>(null);

  return (
    <Grid item xs={12}>
      {type === "table" && (
        <AIMeetingTable
          id={id}
          articleType="미팅노트"
          setNoteId={setNoteId}
          setType={setType}
        />
      )}
      {type === "detail" && (
        <AIMeetingView id={id} noteId={noteId} setType={setType} />
      )}
      {type === "edit" && (
        <AIMeetingEdit id={id} meetingId={noteId} setType={setType} />
      )}
    </Grid>
  );
}

export default AIMeetingNote;
