import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid, Tooltip } from "@mui/material";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { useState } from "react";
import { CommentTab, CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { CommentApi } from "../../../system/api/Comment";
import { useUserState } from "../../../system/context/UserContext";
import { CommentInfo } from "../../../system/types/type";

interface CommentBoxProps {
  data: CommentInfo;
  targetId: number;
  setCommentNumber?: any;
  setCommentType: any;
}

function CommentBox({
  data,
  targetId,
  setCommentNumber,
  setCommentType,
}: CommentBoxProps) {
  const user = useUserState();
  const [comment, setComment] = useState<CommentInfo>(data);
  let checkDate: any;

  // 댓글 등록
  const onChangecomment = (e: any) => {
    if (e.target.name === "examine") {
      setComment({
        ...comment,
        examine: !comment.examine,
        dueDate: moment(new Date()).format("yyyy-MM-DD"),
      });
    } else if (e.target.name === "dueDate") {
      setComment({
        ...comment,
        dueDate: moment(e.target.value).format("yyyy-MM-DD"),
      });
    } else {
      setComment({ ...comment, [e.target.name]: e.target.value });
    }
  };

  const onSave = () => {
    let promise: Promise<any>;
    if (comment.id === 0) {
      promise = CommentApi.CreateComment({ ...comment, targetId: targetId });
    } else {
      promise = CommentApi.UpdateComment(comment);
    }

    promise
      .then((res) => {
        setComment(data);
        if (setCommentNumber) {
          setCommentNumber(new Date());
        }

        setCommentType("view");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  };

  return (
    <Grid container style={{ marginBottom: "10px" }}>
      <Grid item xs={12}>
        <textarea
          style={{ width: "100%", resize: "none", fontSize: "14px" }}
          value={comment.content}
          onChange={onChangecomment}
          placeholder="내용을 입력해주세요."
          name="content"
        ></textarea>
      </Grid>
      <Grid item xs={4}>
        {user.roles.includes("Admin") ? (
          <CommentTab>
            <Grid item xs={6}>
              <CheckBoxComponent
                label="확인 요청"
                name="examine"
                onChange={onChangecomment}
                checked={comment.examine}
              />
              <Tooltip
                style={{ marginLeft: "5px" }}
                title="확인 요청일을 설정할 경우, PM에게 확인 요청일 기준 D-3, D-1에 해당 내용이 메일로 전송됩니다. 확인 요청일을 당일로 설정한 경우 메일은 전송되지 않습니다."
              >
                <HelpOutlineIcon />
              </Tooltip>
            </Grid>
            {comment.examine && (
              <Grid item xs={6}>
                <DatePickerComponent
                  name="dueDate"
                  onChange={onChangecomment}
                  format="yyyy-MM-dd"
                  value={new Date(comment.dueDate)}
                  min={new Date()}
                  focus={(e) => checkDate.show()}
                  ref={(scope) => {
                    checkDate = scope;
                  }}
                />
              </Grid>
            )}
          </CommentTab>
        ) : (
          <Grid item xs={4}></Grid>
        )}
      </Grid>
      <Grid item xs={8} textAlign={"end"}>
        <CustomButton onClick={onSave}>등록</CustomButton>
      </Grid>
    </Grid>
  );
}

export default CommentBox;
