import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CustomButton,
  DataGridContent,
  StyledBodyPa5,
  StyledHead,
  TextNoMargin,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import {
  ASMT_STATUS,
  SEARCH_NAME_INTERN,
  SearchYears,
} from "../../../system/Constants";
import { CommonWorkApi } from "../../../system/api/CommonApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { InternWorkList } from "../../../system/types/InternWork";
import { InitInternWorkList } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import { useRecoilState } from "recoil";
import { searchDataState, searchDetailState } from "../../../system/atoms";
import { useUserState } from "../../../system/context/UserContext";
import StatusChip from "../../Common/StatusChip";

function OwnedInternTable() {
  const history = useHistory();
  const [searchData, setSearchData] = useRecoilState(searchDataState);
  const [searchDetail, setSearchDetail] = useRecoilState(searchDetailState);
  const [data, setData] = useState<InternWorkList>(InitInternWorkList);
  const fields: object = { text: "text", value: "value" };
  const Loading = useLoadingDispatch();
  const user = useUserState();

  const onWriteInternWork = () => {
    history.push({
      pathname: `${AsmtWorkRoutes.intern}/edit`,
    });
  };

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      history.push({
        pathname: `${AsmtWorkRoutes.intern}/detail/${selectedrecords[0].id}`,
      });
    }
  };

  useEffect(() => {
    CommonWorkApi.GetAsmtWorkList("intern", "owned", searchData)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [searchData]);

  const downloadFile = () => {
    CommonWorkApi.GetAsmtWorkList("intern", "owned", {
      ...searchData,
      isFile: true,
      size: 100000,
    })
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onPageChange = (args: any, value: number) => {
    setSearchData({ ...searchData, page: value - 1 });
  };

  const onClickSearch = () => {
    setSearchData({
      ...searchData,
      searchType: searchDetail.type,
      searchWord: searchDetail.word,
      page: 0,
    });
  };

  const onChangeSearch = (args: any) => {
    setSearchDetail({
      ...searchDetail,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChange = (args: any) => {
    setSearchData({
      ...searchData,
      [args.target.name]: args.target.value,
      page: 0,
    });
  };

  const partTemplate = useCallback((props: any): any => {
    let list: WorkUser[] = props.participants.filter(
      (x: WorkUser) => !x.mentor
    );
    return <>{list.map((x) => x.department).join("/")}</>;
  }, []);

  const template = useCallback((props: any): any => {
    let list: WorkUser[] = props.participants.filter(
      (x: WorkUser) => !x.mentor
    );
    if (list.length > 2) {
      return <>{list[0].name + " 외 " + (list.length - 1) + "명"}</>;
    } else {
      return <>{list.map((x) => x.name).join(",")}</>;
    }
  }, []);

  const mentorTemplate = useCallback((props: any): any => {
    let mentorList: WorkUser[] = props.participants.filter(
      (x: WorkUser) => x.mentor
    );
    if (mentorList.length > 2) {
      return (
        <>{mentorList[0].name + " 외 " + (mentorList.length - 1) + "명"}</>
      );
    } else {
      return <>{mentorList.map((x) => x.name).join(",")}</>;
    }
  }, []);

  const statusTemplate = useCallback(
    (props: any): any => {
      let InternStatus = props.status;
      let InternSubStatus = props.subStatus;
      if (user.roles.includes("Admin")) {
        if (props.subStatus) {
          return (
            <DataGridContent>
              <StatusChip
                status={InternStatus}
                subStatus={InternSubStatus}
              ></StatusChip>
            </DataGridContent>
          );
        } else {
          return (
            <DataGridContent>
              <StatusChip status={InternStatus} subStatus=""></StatusChip>
            </DataGridContent>
          );
        }
      } else {
        if (props.subStatus) {
          return (
            <>
              {InternStatus}-{InternSubStatus}
            </>
          );
        } else {
          return (
            <>
              <StatusChip status={InternStatus} subStatus=""></StatusChip>
            </>
          );
        }
      }
    },
    [user.roles]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitleDiv title="인턴 과제 등록 및 조회"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  기간검색
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="year"
                    value={searchData.year}
                    cssClass="e-outline"
                    dataSource={SearchYears}
                    onChange={onChange}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledHead width={"80"} align="center">
                  진행상태
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="status"
                    value={searchData.status}
                    cssClass="e-outline"
                    onChange={onChange}
                    fields={fields}
                    dataSource={ASMT_STATUS}
                  ></DropDownListComponent>
                </StyledBodyPa5>
              </TableRow>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  검색어
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="type"
                    cssClass="e-outline"
                    value={searchDetail.type}
                    onChange={onChangeSearch}
                    dataSource={SEARCH_NAME_INTERN}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledBodyPa5 align="center" colSpan={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <TextNoMargin>
                        <TextBoxComponent
                          placeholder="검색어를 입력하세요."
                          cssClass="e-outline"
                          name="word"
                          width="99%"
                          value={searchDetail.word}
                          onChange={onChangeSearch}
                        ></TextBoxComponent>
                      </TextNoMargin>
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end">
                      <CustomButton onClick={onClickSearch}>검색</CustomButton>
                    </Grid>
                  </Grid>
                </StyledBodyPa5>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right", marginTop: 1 }}>
        <CustomButton
          onClick={downloadFile}
          iconCss="e-icons e-download"
          sx={{ marginRight: 1 }}
        >
          엑셀 다운로드
        </CustomButton>
        <CustomButton onClick={onWriteInternWork} iconCss="e-icons e-plus">
          인턴 과제 등록
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <GridComponent
          dataSource={data.works}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="participants"
              template={partTemplate}
              headerText="파트"
              textAlign="Center"
              width="110"
            />
            <ColumnDirective
              field="participants"
              template={template}
              headerText="이름"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="title"
              headerText="과제명"
              headerTextAlign="Center"
              width="450"
            />
            <ColumnDirective
              field="participants"
              template={mentorTemplate}
              headerText="멘토"
              width="100"
              textAlign="Center"
            />
            <ColumnDirective
              field="status"
              headerText="진행상태"
              width="150"
              textAlign="Center"
              template={statusTemplate}
            />
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <Grid item xs={6}>
        <Pagination
          count={data.totalPages}
          page={searchData.page + 1}
          onChange={onPageChange}
          showFirstButton
          showLastButton
          defaultPage={10}
          boundaryCount={2}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign={"right"}>
          <span>
            {searchData.page + 1} of {data.totalPages} pages
          </span>
          <span>({data.totalElements} items)</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default OwnedInternTable;
