import { Grid } from "@mui/material";
import { useState } from "react";
import AIReportTable from "./AIReportTable";
import AIReportView from "./AIReportView";
import AIReportEdit from "./AIReportEdit";

interface AIReportProps {
  id: number;
}
function AIReport({ id }: AIReportProps) {
  // table, detail, edit
  const [type, setType] = useState("table");
  const [noteId, setNoteId] = useState<number | null>(null);

  return (
    <Grid item xs={12}>
      {type === "table" && (
        <AIReportTable
          id={id}
          articleType="보고서"
          setNoteId={setNoteId}
          setType={setType}
        />
      )}
      {type === "detail" && (
        <AIReportView id={id} noteId={noteId} setType={setType} />
      )}
      {type === "edit" && (
        <AIReportEdit id={id} meetingId={noteId} setType={setType} />
      )}
    </Grid>
  );
}

export default AIReport;
