import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import _ from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  StatisticsTitle,
  StyledBodyName,
  StyledBodyNoPadding,
  StyledBodyNoPaddingNumber,
  StyledBodyNoPaddingPart,
  StyledHead1,
  StyledHead2,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import StatisticsApi from "../../../system/api/StatisticsApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AggExecutive, AggTeamList } from "../../../system/types/Statistics";
import { AdminRoutes } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import { SearchYears } from "../../../system/Constants";
import uuid from "react-uuid";
import InfoIcon from "@mui/icons-material/Info";
function StatisticsTable() {
  const [year, setYear] = useState<string>("2024");
  const [, setParticipationType] = useState<string>("전체"); // 전체, PM, 멤버
  const [, setShow] = useState<string>("");
  const [data, setData] = useState<AggTeamList>();
  const [exeData, setExeData] = useState<AggExecutive[]>([]);
  const [, setSelectExe] = useState<AggExecutive>();
  const Loading = useLoadingDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [openPartNames, setOpenPartNames] = useState<string[]>([]);
  const onChangeYear = (args: any) => {
    setYear(args.target.value);
  };

  useEffect(() => {
    Loading({ type: "LOADING" });
    StatisticsApi.GetTeamStatistics(year)
      .then((res) => {
        setData(res.data);
        StatisticsApi.GetExecutiveStatistics(year)
          .then((res) => {
            setExeData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading, year]);

  const onChangeOpen = (item: string) => {
    if (!openPartNames.includes(item)) {
      // 포함하지 않고 있다면
      let names = _.concat(openPartNames, item);
      setOpenPartNames(names);
    } else {
      //포함하고 있다면
      _.remove(openPartNames, (name) => name === item);
      let names: string[] = [];
      openPartNames.forEach((name) => names.push(name));
      setOpenPartNames(names);
    }
  };

  const onHandleCell = (
    arg: string,
    department: string,
    team: string,
    participationType: string | null
  ) => {
    setShow(arg);
    if (participationType) {
      setParticipationType(participationType);
    } //집계 테이블에서 참여자 그룹에 활용 됨(ppt 기준 1~4)}

    ref.current?.scrollIntoView({ behavior: "smooth" });
    if (arg === "pct") {
      window.open(
        `${window.location.origin}${AdminRoutes.statisticsPct}/?year=${year}&department=${department}&team=${team}&participationType=${participationType}`,
        "Inbody_works",
        "width=1600,height=800,location=no,status=no,scrollbars=yes"
      );
    }
    if (arg === "nonpct") {
      window.open(
        `${window.location.origin}${AdminRoutes.statisticsNonpct}/?year=${year}&department=${department}&team=${team}&participationType=${participationType}`,
        "Inbody_works_nonpct",
        "width=1600,height=800,location=no,status=no,scrollbars=yes"
      );
    }
    if (arg === "meritPct") {
      window.open(
        `${window.location.origin}${AdminRoutes.statisticsMeritpct}/?year=${year}&department=${department}&team=${team}`,
        "Inbody_works_meritPct",
        "width=1600,height=800,location=no,status=no,scrollbars=yes"
      );
    }
  };

  const onHandleCellExecutives = (select: AggExecutive) => {
    setSelectExe(select);
    window.open(
      `${window.location.origin}${AdminRoutes.statisticsExe}/?year=${year}&searchWord=${select.name}`,
      "Inbody_works_exe",
      "width=1600,height=800,location=no,status=no,scrollbars=yes"
    );
  };

  const checkDecimal = (item: number) => {
    if (item % 1 === 0) {
      return item + ".0";
    } else {
      return item;
    }
  };

  const StyledClickBody = styled(TableCell)(({ theme }) => ({
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    padding: "2px 30px 2px 2px",
    textDecoration: "underline",
  }));

  const StyledClickCenterBody = styled(TableCell)(({ theme }) => ({
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    padding: "2px",
    textDecoration: "underline",
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="임직원 과제 참여 현황"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <DropDownListComponent
          cssClass="e-outline"
          placeholder="년도"
          dataSource={SearchYears}
          onChange={onChangeYear}
          value={year}
          width={"120px"}
          style={{ minHeight: "29px" }}
        ></DropDownListComponent>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table style={{ padding: "0px" }}>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center" colSpan={2}>
                  소속
                </StyledHead1>
                <StyledHead1 align="center" rowSpan={1}>
                  참여대상
                </StyledHead1>
                <StyledHead1 align="center" colSpan={4}>
                  참여
                </StyledHead1>
                <StyledHead1 align="center" colSpan={2}>
                  미참여
                </StyledHead1>
                <StyledHead1 align="center" colSpan={3}>
                  성과급
                </StyledHead1>
              </TableRow>
              <TableRow>
                <StyledHead2 align="center">파트</StyledHead2>
                <StyledHead2 align="center">팀</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">참여율(%)</StyledHead2>
                <StyledHead2 align="center">PM(명)</StyledHead2>
                <StyledHead2 align="center">멤버(명)</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">미참여율(%)</StyledHead2>
                <StyledHead2 align="center">
                  인원수(명)
                  <Tooltip title="퇴사자 포함" placement="right">
                    <InfoIcon
                      fontSize="small"
                      color="action"
                      sx={{ marginTop: "-3px", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </StyledHead2>
                <StyledHead2 align="center">금액(원)</StyledHead2>
                <StyledHead2 align="center">평균(원)</StyledHead2>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && (
                <TableRow>
                  <StyledBodyNoPadding align="center" colSpan={2}>
                    계
                  </StyledBodyNoPadding>
                  <StyledBodyNoPadding align="center">
                    {data.total.totalUsers}
                  </StyledBodyNoPadding>

                  {data.total.participants === 0 ? (
                    <StyledBodyNoPaddingNumber align="right">
                      {data.total.participants}
                    </StyledBodyNoPaddingNumber>
                  ) : (
                    <StyledClickBody
                      align="right"
                      onClick={() => onHandleCell("pct", "", "", null)}
                    >
                      {data.total.participants}
                    </StyledClickBody>
                  )}

                  <StyledBodyNoPaddingNumber align="right">
                    {checkDecimal(data.total.participantRatio)}
                  </StyledBodyNoPaddingNumber>
                  {data.total.projectManagers === 0 ? (
                    <StyledBodyNoPaddingNumber align="right">
                      {data.total.projectManagers}
                    </StyledBodyNoPaddingNumber>
                  ) : (
                    <StyledClickBody
                      align="right"
                      onClick={() => onHandleCell("pct", "", "", "PM")}
                    >
                      {data.total.projectManagers}
                    </StyledClickBody>
                  )}
                  {data.total.members === 0 ? (
                    <StyledBodyNoPaddingNumber align="right">
                      {data.total.members}
                    </StyledBodyNoPaddingNumber>
                  ) : (
                    <StyledClickBody
                      align="right"
                      onClick={() => onHandleCell("pct", "", "", "멤버")}
                    >
                      {data.total.members}
                    </StyledClickBody>
                  )}
                  {data.total.nonparticipants === 0 ? (
                    <StyledBodyNoPaddingNumber align="right">
                      {data.total.nonparticipants}
                    </StyledBodyNoPaddingNumber>
                  ) : (
                    <StyledClickBody
                      align="right"
                      onClick={() => onHandleCell("nonpct", "", "", null)}
                    >
                      {data.total.nonparticipants}
                    </StyledClickBody>
                  )}

                  <StyledBodyNoPaddingNumber align="right">
                    {" "}
                    {checkDecimal(data.total.nonparticipantRatio)}
                  </StyledBodyNoPaddingNumber>

                  {data.total.actualParticipants === 0 ? (
                    <StyledBodyNoPaddingNumber align="right">
                      {data.total.actualParticipants}
                    </StyledBodyNoPaddingNumber>
                  ) : (
                    <StyledClickBody
                      align="right"
                      onClick={() => onHandleCell("meritPct", "", "", null)}
                    >
                      {data.total.actualParticipants}
                    </StyledClickBody>
                  )}

                  <StyledBodyNoPaddingNumber align="right">
                    {data.total.totalMeritPay
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </StyledBodyNoPaddingNumber>
                  <StyledBodyNoPaddingNumber align="right">
                    {data.total.avgMeritPay
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </StyledBodyNoPaddingNumber>
                </TableRow>
              )}

              {data &&
                data.parts.map((part, idx) => {
                  const partKeys = data.parts.map(() => uuid());
                  const teamKeys = data.parts.flatMap((part) =>
                    part.teams ? part.teams.map(() => uuid()) : []
                  );

                  return (
                    <Fragment key={partKeys[idx]}>
                      <TableRow>
                        {/* 파트 */}
                        {openPartNames.includes(part.partTotal.department) ? (
                          <StyledBodyNoPaddingPart
                            align="center"
                            colSpan={2}
                            onClick={() =>
                              onChangeOpen(part.partTotal.department)
                            }
                          >
                            {part.partTotal.department}

                            <ArrowDropUpIcon sx={{ fontSize: 15 }} />
                          </StyledBodyNoPaddingPart>
                        ) : (
                          <StyledBodyNoPaddingPart
                            align="center"
                            colSpan={2}
                            onClick={() =>
                              onChangeOpen(part.partTotal.department)
                            }
                          >
                            {part.partTotal.department}

                            <ArrowDropDownIcon sx={{ fontSize: 15 }} />
                          </StyledBodyNoPaddingPart>
                        )}

                        {/* 참여대상 인원수 */}
                        <StyledBodyNoPaddingNumber align="right">
                          {part.partTotal.partMembers}
                        </StyledBodyNoPaddingNumber>
                        {/* 참여 - 인원수 */}
                        {part.partTotal.participants === 0 ? (
                          <StyledBodyNoPaddingNumber align="right">
                            {" "}
                            {part.partTotal.participants}
                          </StyledBodyNoPaddingNumber>
                        ) : (
                          <StyledClickBody
                            className="pct"
                            onClick={() =>
                              onHandleCell(
                                "pct",
                                part.partTotal.department,
                                "",
                                "전체"
                              )
                            }
                            align="right"
                          >
                            {part.partTotal.participants}
                          </StyledClickBody>
                        )}

                        {/* 참여 - 참여율 */}
                        <StyledBodyNoPaddingNumber align="right">
                          {checkDecimal(part.partTotal.participantRatio)}
                        </StyledBodyNoPaddingNumber>
                        {/* 참여 - PM */}

                        {part.partTotal.projectManagers === 0 ? (
                          <StyledBodyNoPaddingNumber align="right">
                            {" "}
                            {part.partTotal.projectManagers}
                          </StyledBodyNoPaddingNumber>
                        ) : (
                          <StyledClickBody
                            className="pct"
                            onClick={() =>
                              onHandleCell(
                                "pct",
                                part.partTotal.department,
                                "",
                                "PM"
                              )
                            }
                            align="right"
                          >
                            {part.partTotal.projectManagers}
                          </StyledClickBody>
                        )}

                        {/* 참여 - 맴버 */}

                        {part.partTotal.members === 0 ? (
                          <StyledBodyNoPaddingNumber align="right">
                            {" "}
                            {part.partTotal.members}
                          </StyledBodyNoPaddingNumber>
                        ) : (
                          <StyledClickBody
                            className="pct"
                            onClick={() =>
                              onHandleCell(
                                "pct",
                                part.partTotal.department,
                                "",
                                "멤버"
                              )
                            }
                            align="right"
                          >
                            {part.partTotal.members}
                          </StyledClickBody>
                        )}

                        {/* 미참여 - 인원수 */}

                        {part.partTotal.nonparticipants === 0 ? (
                          <StyledBodyNoPaddingNumber align="right">
                            {" "}
                            {part.partTotal.nonparticipants}
                          </StyledBodyNoPaddingNumber>
                        ) : (
                          <StyledClickBody
                            className="nonpct"
                            onClick={() =>
                              onHandleCell(
                                "nonpct",
                                part.partTotal.department,
                                "",
                                "전체"
                              )
                            }
                            align="right"
                          >
                            {part.partTotal.nonparticipants}
                          </StyledClickBody>
                        )}

                        {/* 미참여 - 미참여율 */}
                        <StyledBodyNoPaddingNumber align="right">
                          {checkDecimal(part.partTotal.nonparticipantRatio)}
                        </StyledBodyNoPaddingNumber>
                        {/* 성과급 - 인원수 */}

                        {part.partTotal.actualParticipants === 0 ? (
                          <StyledBodyNoPaddingNumber align="right">
                            {" "}
                            {part.partTotal.actualParticipants}
                          </StyledBodyNoPaddingNumber>
                        ) : (
                          <StyledClickBody
                            className="meritpct"
                            onClick={() =>
                              onHandleCell(
                                "meritPct",
                                part.partTotal.department,
                                "",
                                "전체"
                              )
                            }
                            align="right"
                          >
                            {part.partTotal.actualParticipants}
                          </StyledClickBody>
                        )}

                        {/* 성과급 - 금액 */}
                        <StyledBodyNoPaddingNumber align="right">
                          {part.partTotal.totalMeritPay
                            .toLocaleString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledBodyNoPaddingNumber>
                        {/* 성과급 - 평균 */}
                        <StyledBodyNoPaddingNumber align="right">
                          {part.partTotal.avgMeritPay
                            .toLocaleString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledBodyNoPaddingNumber>
                      </TableRow>
                      {part.teams &&
                        part.teams.map((team, idx) => {
                          return (
                            <Fragment key={teamKeys[idx]}>
                              {openPartNames.includes(team.department) ? (
                                <TableRow>
                                  {/* 파트 */}
                                  <StyledBodyName align="center">
                                    {team.department}
                                  </StyledBodyName>
                                  {/* 팀 */}
                                  <StyledBodyName align="center">
                                    {team.team}
                                  </StyledBodyName>
                                  {/* 참여대상 인원수 */}
                                  <StyledBodyNoPaddingNumber align="right">
                                    {team.totalTeamMembers}
                                  </StyledBodyNoPaddingNumber>
                                  {/* 참여 - 인원수 */}
                                  {team.participants === 0 ? (
                                    <StyledBodyNoPaddingNumber align="right">
                                      {" "}
                                      {team.participants}
                                    </StyledBodyNoPaddingNumber>
                                  ) : (
                                    <StyledClickBody
                                      className="pct"
                                      onClick={() =>
                                        onHandleCell(
                                          "pct",
                                          team.department,
                                          team.team,
                                          "전체"
                                        )
                                      }
                                      align="right"
                                    >
                                      {team.participants}
                                    </StyledClickBody>
                                  )}

                                  {/* 참여 - 참여율 */}
                                  <StyledBodyNoPaddingNumber align="right">
                                    {checkDecimal(team.participantRatio)}
                                  </StyledBodyNoPaddingNumber>
                                  {/* 참여 - PM */}

                                  {team.projectManagers === 0 ? (
                                    <StyledBodyNoPaddingNumber align="right">
                                      {" "}
                                      {team.projectManagers}
                                    </StyledBodyNoPaddingNumber>
                                  ) : (
                                    <StyledClickBody
                                      className="pct"
                                      onClick={() =>
                                        onHandleCell(
                                          "pct",
                                          team.department,
                                          team.team,
                                          "PM"
                                        )
                                      }
                                      align="right"
                                    >
                                      {team.projectManagers}
                                    </StyledClickBody>
                                  )}

                                  {/* 참여 - 맴버 */}

                                  {team.members === 0 ? (
                                    <StyledBodyNoPaddingNumber align="right">
                                      {" "}
                                      {team.members}
                                    </StyledBodyNoPaddingNumber>
                                  ) : (
                                    <StyledClickBody
                                      className="pct"
                                      onClick={() =>
                                        onHandleCell(
                                          "pct",
                                          team.department,
                                          team.team,
                                          "멤버"
                                        )
                                      }
                                      align="right"
                                    >
                                      {team.members}
                                    </StyledClickBody>
                                  )}

                                  {/* 미참여 - 인원수 */}

                                  {team.nonparticipants === 0 ? (
                                    <StyledBodyNoPaddingNumber align="right">
                                      {" "}
                                      {team.nonparticipants}
                                    </StyledBodyNoPaddingNumber>
                                  ) : (
                                    <StyledClickBody
                                      className="nonpct"
                                      onClick={() =>
                                        onHandleCell(
                                          "nonpct",
                                          team.department,
                                          team.team,
                                          "전체"
                                        )
                                      }
                                      align="right"
                                    >
                                      {team.nonparticipants}
                                    </StyledClickBody>
                                  )}

                                  {/* 미참여 - 미참여율 */}
                                  <StyledBodyNoPaddingNumber align="right">
                                    {checkDecimal(team.nonparticipantRatio)}
                                  </StyledBodyNoPaddingNumber>
                                  {/* 성과급 - 인원수 */}

                                  {team.actualParticipants === 0 ? (
                                    <StyledBodyNoPaddingNumber align="right">
                                      {" "}
                                      {team.actualParticipants}
                                    </StyledBodyNoPaddingNumber>
                                  ) : (
                                    <StyledClickBody
                                      className="meritpct"
                                      onClick={() =>
                                        onHandleCell(
                                          "meritPct",
                                          team.department,
                                          team.team,
                                          "전체"
                                        )
                                      }
                                      align="right"
                                    >
                                      {team.actualParticipants}
                                    </StyledClickBody>
                                  )}

                                  {/* 성과급 - 금액 */}
                                  <StyledBodyNoPaddingNumber align="right">
                                    {team.totalMeritPay
                                      .toLocaleString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </StyledBodyNoPaddingNumber>
                                  {/* 성과급 - 평균 */}
                                  <StyledBodyNoPaddingNumber align="right">
                                    {team.avgMeritPay
                                      .toLocaleString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </StyledBodyNoPaddingNumber>
                                </TableRow>
                              ) : null}
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={4}>
        <StatisticsTitle>
          임원/그룹장/파트장 과제 참여 현황 (멘토, PM, 참여자)
        </StatisticsTitle>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">이름</StyledHead1>
                <StyledHead1 align="center">직책 / 직급</StyledHead1>
                <StyledHead1 align="center">그룹</StyledHead1>
                <StyledHead1 align="center">파트</StyledHead1>
                <StyledHead1 align="center">참여 과제수</StyledHead1>
              </TableRow>
            </TableHead>
            <TableBody>
              {exeData.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <StyledBodyNoPadding align="center">
                      {item.name}
                    </StyledBodyNoPadding>
                    <StyledBodyNoPadding align="center">
                      {item.jobPosition.includes("사장") ||
                      item.jobPosition.includes("이사") ? (
                        <>{item.jobPosition}</>
                      ) : (
                        <>{item.jobTitle}</>
                      )}
                    </StyledBodyNoPadding>
                    <StyledBodyNoPadding align="center">
                      {item.group}
                    </StyledBodyNoPadding>
                    <StyledBodyNoPadding align="center">
                      {item.department}
                    </StyledBodyNoPadding>
                    {/* 참여 과제수 */}
                    {item.totalWorks > 0 ? (
                      <StyledClickCenterBody
                        className="executive"
                        onClick={() => onHandleCellExecutives(item)}
                        align="center"
                      >
                        {item.totalWorks}
                      </StyledClickCenterBody>
                    ) : (
                      <StyledBodyNoPadding align="center">
                        {" "}
                        {item.totalWorks}
                      </StyledBodyNoPadding>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default StatisticsTable;
