import { Typography } from "@mui/material";
import {
  ButtonComponent,
  ChipListComponent,
} from "@syncfusion/ej2-react-buttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { useEffect, useState } from "react";
import { PRIMARY, SECONDARY } from "../../../styles/theme";
import { WorkUser } from "../../../system/types/type";

interface ContributionModalProps {
  list: WorkUser[];
  onChangeEmp: (userList: WorkUser[]) => void;
  mentorEmpList: WorkUser[];
}

function ContributionModal({
  list,
  onChangeEmp,
  mentorEmpList,
}: ContributionModalProps) {
  const [userList, setUserList] = useState<WorkUser[]>([]);

  useEffect(() => {
    setUserList(list);
  }, [list]);

  let dialogInstance: DialogComponent;
  const buttons: any = [
    {
      buttonModel: {
        content: "저장",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        var checkMentor = false;
        for (let i = 0; userList.length > i; i++) {
          if (
            mentorEmpList.filter(
              (item) =>
                item.name === userList[i].name &&
                (userList[i].contributionRate === undefined ||
                  userList[i].contributionRate === 0)
            ).length > 0
          ) {
            checkMentor = true;
          }
        }

        if (
          userList.filter((x) => x.contributionRate === null).length === 0 &&
          userList
            .filter((x) => x.contributionRate)
            .reduce((x, y) => x + (y.contributionRate || 0), 0) === 100
        ) {
          if (
            userList.filter(
              (x) =>
                x.mentor &&
                (x.contributionRate === undefined || x.contributionRate === 0)
            ).length > 0 ||
            checkMentor
          ) {
            alert(
              " 과제 멘토를 과제 참여자로 등록하려면 \n 과제 멘토 기여도를 0 보다 큰 숫자로 입력해주셔야 합니다."
            );
          } else {
            dialogInstance.hide();
            onChangeEmp(userList);
          }
        } else {
          alert("모든 참여자 기여도가 0이상, 기여도 합이 100이 되어야 합니다.");
        }
      },
    },
  ];

  const handleClick = () => {
    if (userList.length > 0) {
      dialogInstance.show();
    } else if (userList.length === 0) {
      alert("참여자를 등록해 주세요.");
    }
  };

  const onchange = (e: any, id: number) => {
    const newList = userList.map((x) =>
      x.id === id
        ? {
            ...x,
            contributionRate: e.target.value,
          }
        : x
    );
    setUserList(newList);
  };

  const cntButton = {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DCDFE3",
    height: "36px",
    boxShadow: "none",
  };

  return (
    <div>
      <ButtonComponent
        style={cntButton}
        onClick={handleClick}
        iconCss="e-icons e-edit"
      >
        기여도 설정
      </ButtonComponent>
      <DialogComponent
        header="기여도 설정"
        width="500px"
        showCloseIcon={true}
        isModal={true}
        buttons={buttons}
        closeOnEscape={false}
        visible={false}
        ref={(dialog) => (dialogInstance = dialog!)}
      >
        <div style={{ maxHeight: "700px" }}>
          {userList.length > 0 ? (
            <>
              <Typography variant="caption">
                모든 참여자 기여도가 0이상, 기여도 합이 100이 되어야 합니다.
              </Typography>
              {userList.map((x) => {
                return (
                  <form key={x.id}>
                    <ChipListComponent
                      style={{
                        backgroundColor: x.projectManager ? PRIMARY : SECONDARY,
                        color: "white",
                      }}
                      text={x.projectManager ? "PM " + x.name : x.name}
                    ></ChipListComponent>
                    <span>
                      {" "}
                      <NumericTextBoxComponent
                        style={{ fontSize: "1rem" }}
                        width="100px"
                        format="###.##"
                        value={x.contributionRate || 0}
                        decimals={2}
                        min={0}
                        max={100}
                        step={1}
                        onChange={(e: any) => onchange(e, x.id)}
                      />
                    </span>
                    {mentorEmpList.filter(
                      (item) =>
                        item.name === x.name &&
                        (x.contributionRate === undefined ||
                          x.contributionRate === 0)
                    ).length > 0 ? (
                      <div
                        className="e-icons e-circle-check"
                        style={{ color: "#CC1F3B", fontSize: "0.8rem" }}
                      >
                        {" "}
                        과제 멘토를 과제 참여자로 등록하려면 기여도를
                        입력해주셔야 합니다.
                      </div>
                    ) : null}
                  </form>
                );
              })}
              <hr />
              <div style={{ marginLeft: "10px" }}>
                합 :{" "}
                {userList
                  .filter((x) => x.contributionRate)
                  .reduce((x, y) => x + (y.contributionRate || 0), 0)
                  .toFixed(2)}
              </div>
            </>
          ) : (
            <p>선택된 참여자가 없습니다.</p>
          )}
        </div>
      </DialogComponent>
    </div>
  );
}

export default ContributionModal;
