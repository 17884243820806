import { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import AsmtAdminEdit from "../components/Admin/Asmt/AsmtAdminEdit";
import InternAdminEdit from "../components/Admin/Asmt/InternAdminEdit";
import InternEdit from "../components/Asmt/Intern/InternEdit";
import InternView from "../components/Asmt/Intern/InternView";
import AsmtEdit from "../components/Asmt/Main/AsmtEdit";
import AsmtView from "../components/Asmt/Main/AsmtView";
import QnAEdit from "../components/Guide/QnA/QnAEdit";
import QnAView from "../components/Guide/QnA/QnAView";
import MeetingEdit from "../components/Note/Meeting/MeetingEdit";
import MeetingTable from "../components/Note/Meeting/MeetingTable";
import ReportEdit from "../components/Note/Report/ReportEdit";
import ReportTable from "../components/Note/Report/ReportTable";
import ReportView from "../components/Note/Report/ReportView";
import NoticeEdit from "../components/Notice/NoticeEdit";
import NoticeView from "../components/Notice/NoticeView";
import AdminAsmtMain from "../pages/Admin/AdminAsmtMain";
import AdminRoleMain from "../pages/Admin/AdminRoleMain";
import StatisticExec from "../pages/Admin/PopUP/StatisticExec";
import StatisticsMeritpct from "../pages/Admin/PopUP/StatisticsMeritpct";
import StatisticsNonpct from "../pages/Admin/PopUP/StatisticsNonpct";
import StatisticsPct from "../pages/Admin/PopUP/StatisticsPct";
import StatisticsMain from "../pages/Admin/StatisticsMain";
import ApproveDetail from "../pages/Approve/ApproveDetail";
import AsmtMain from "../pages/Asmt/AsmtMain";
import InternMain from "../pages/Asmt/InternMain";
import MemberMain from "../pages/Asmt/MemberMain";
import OwnedInternMain from "../pages/Asmt/OwnedInternMain";
import OwnedMain from "../pages/Asmt/OwnedMain";
import DailyDetailMain from "../pages/Daily/DailyDetailMain";
import DailyEditMain from "../pages/Daily/DailyEditMain";
import DailyMain from "../pages/Daily/DailyMain";
import QnAMain from "../pages/Guide/QnAMain";
import Content from "../pages/Layout/Content";
import Footer from "../pages/Layout/Footer";
import Header from "../pages/Layout/Header";
import LandingMain from "../pages/Layout/LandingMain";
import NoticeMain from "../pages/Notice/NoticeMain";
import { BodyWrap } from "../styles/theme";
import { useUserState } from "../system/context/UserContext";
import {
  AdminRoutes,
  AIRoutes,
  ApproveRoutes,
  AsmtWorkRoutes,
  CommonRoutes,
  DailyWorkRoutes,
  GuideRoutes,
  MeetingNoteRoutes,
  NoticeRoutes,
  ReportRoutes,
} from "../system/types/type";
import Guide from "../components/Guide/Guide";
import { useRecoilState } from "recoil";
import { leftDrawerOpenState } from "../system/atoms";
import ActionItemMain from "../pages/ActionItem/ActionItemMain";
import ActionItemDetailMain from "../pages/ActionItem/ActionItemDetailMain";
import AIMeetingView from "../components/ActionItem/AIMeeting/AIMeetingView";

function Routes(): ReactElement {
  const [, setOpen] = useRecoilState(leftDrawerOpenState);
  const handleMenuOpen = (open: boolean) => {
    setOpen(open);
  };
  const user = useUserState();

  return (
    <>
      <BodyWrap>
        {/* Header - Navigation Menu */}
        <Header></Header>
        {/* Body - Content */}
        <Content>
          <Switch>
            {/* 사이트 메인 페이지 */}
            <Route
              path={CommonRoutes.root}
              component={LandingMain}
              exact
            ></Route>

            {/* 공지사항 */}
            <Route
              path={NoticeRoutes.root}
              component={NoticeMain}
              exact
            ></Route>
            {user.roles.includes("Admin") && (
              <Route
                path={NoticeRoutes.edit}
                component={NoticeEdit}
                exact
              ></Route>
            )}
            <Route
              path={NoticeRoutes.detail}
              component={NoticeView}
              exact
            ></Route>
            {/* 과제업무 */}
            <Route
              path={AsmtWorkRoutes.root}
              component={AsmtMain}
              exact
            ></Route>
            <Route
              path={AsmtWorkRoutes.edit}
              component={AsmtEdit}
              exact
            ></Route>
            <Route
              path={AsmtWorkRoutes.detail}
              component={AsmtView}
              exact
            ></Route>

            <Route
              path={AsmtWorkRoutes.owned}
              component={OwnedMain}
              exact
            ></Route>

            <Route
              path={AsmtWorkRoutes.intern}
              component={OwnedInternMain}
              exact
            ></Route>
            <Route
              path={AsmtWorkRoutes.internList}
              component={InternMain}
              exact
            ></Route>
            <Route
              path={AsmtWorkRoutes.internEdit}
              component={InternEdit}
              exact
            ></Route>
            <Route
              path={AsmtWorkRoutes.internDetail}
              component={InternView}
              exact
            ></Route>

            <Route
              path={AsmtWorkRoutes.members}
              component={MemberMain}
              exact
            ></Route>
            {/* ActionItem */}
            <Route
              path={AIRoutes.root}
              component={ActionItemMain}
              exact
            ></Route>
            <Route
              path={AIRoutes.detail}
              component={ActionItemDetailMain}
              exact
            ></Route>

            {/* 일일업무 보고 */}
            <Route
              path={DailyWorkRoutes.root}
              component={DailyMain}
              exact
            ></Route>
            <Route
              path={DailyWorkRoutes.detail}
              component={DailyDetailMain}
              exact
            ></Route>
            <Route
              path={DailyWorkRoutes.edit}
              component={DailyEditMain}
              exact
            ></Route>
            {/* 미팅노트 */}
            <Route
              path={MeetingNoteRoutes.root}
              component={MeetingTable}
              exact
            ></Route>
            <Route
              path={MeetingNoteRoutes.edit}
              component={MeetingEdit}
              exact
            ></Route>
            <Route
              path={MeetingNoteRoutes.detail}
              component={AIMeetingView}
              exact
            ></Route>
            {/* 개발노트 */}
            {/* <Route path={DevelopNoteRoutes.root} component={DevelopTable} exact></Route>
            <Route path={DevelopNoteRoutes.edit} component={DevelopEdit} exact></Route>
            <Route path={DevelopNoteRoutes.detail} component={DevelopView} exact></Route> */}
            {/* 보고서 */}
            <Route
              path={ReportRoutes.root}
              component={ReportTable}
              exact
            ></Route>
            <Route
              path={ReportRoutes.edit}
              component={ReportEdit}
              exact
            ></Route>
            <Route
              path={ReportRoutes.detail}
              component={ReportView}
              exact
            ></Route>
            {/* 가이드 - 메뉴얼, qna */}
            <Route path={GuideRoutes.guide} component={Guide} exact></Route>
            <Route path={GuideRoutes.qnaRoot} component={QnAMain} exact></Route>
            <Route path={GuideRoutes.qnaEdit} component={QnAEdit} exact></Route>
            <Route
              path={GuideRoutes.qnaDetail}
              component={QnAView}
              exact
            ></Route>
            {/* 승인 */}
            <Route
              path={ApproveRoutes.detail}
              component={ApproveDetail}
              exact
            ></Route>
            {/* 설정 */}
            {(user.roles.includes("Admin") ||
              user.roles.includes("ActionItem.admin")) && (
              <>
                <Route
                  path={AdminRoutes.asmtEdit}
                  component={AsmtAdminEdit}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.internEdit}
                  component={InternAdminEdit}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.asmt}
                  component={AdminAsmtMain}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.statistics}
                  component={StatisticsMain}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.statisticsPct}
                  component={() => (
                    <StatisticsPct handleOpen={handleMenuOpen} />
                  )}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.statisticsNonpct}
                  component={() => (
                    <StatisticsNonpct handleOpen={handleMenuOpen} />
                  )}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.statisticsMeritpct}
                  component={() => (
                    <StatisticsMeritpct handleOpen={handleMenuOpen} />
                  )}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.statisticsExe}
                  component={() => (
                    <StatisticExec handleOpen={handleMenuOpen} />
                  )}
                  exact
                ></Route>
                <Route
                  path={AdminRoutes.role}
                  component={AdminRoleMain}
                  exact
                ></Route>
              </>
            )}
          </Switch>
        </Content>
      </BodyWrap>
      {/* Footer */}
      <Footer></Footer>
    </>
  );
}

export default Routes;
