import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { ActionItemDetailInfo } from "../../system/types/ActionItem";
import { Grid, styled, Tab, Tabs } from "@mui/material";
import TitleDiv from "../Common/TitleDiv";
import { Brand600 } from "../../styles/Colors";
import ActionItemDetails from "./ActionItemDetails";
import AIMeetingNote from "./AIMeeting/AIMeetingNote";
import AIReport from "./AIReport/AIReport";

interface Params {
  id: string;
}
function ActionItemTabs() {
  const { id } = useParams<Params>();
  const actionItemId = parseInt(id);
  const [data, setData] = useState<ActionItemDetailInfo | null>(null);
  const [value, setValue] = useState(1);

  const refreshData = useCallback(() => {
    ActionItemsApi.getActionItemsDetail(actionItemId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [actionItemId]);

  useEffect(() => {
    refreshData();
  }, [refreshData, actionItemId]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {data && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TitleDiv title="A/I(Action Item) 상세"></TitleDiv>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={value}
              textColor="inherit"
              TabIndicatorProps={{
                style: { background: Brand600 },
              }}
              onChange={handleChange}
              style={{ marginBottom: "10px" }}
            >
              <Tab label={<span>상세</span>} value={1} />
              <Tab label={<span>미팅노트</span>} value={2} />
              <Tab label={<span>보고서</span>} value={3} />
            </Tabs>
            <ContentScrollBox container item xs={12}>
              {value === 1 && (
                <ActionItemDetails data={data} refreshData={refreshData} />
              )}
              {value === 2 && <AIMeetingNote id={data.id} />}
              {value === 3 && <AIReport id={data.id} />}
            </ContentScrollBox>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ActionItemTabs;

const ContentScrollBox = styled(Grid)({
  height: "calc(100vh - 200px)",
  overflow: "scroll",
});
