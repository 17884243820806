import { Chip, Grid } from "@mui/material";
import {
  AILastContentBox,
  AITableCardBox,
  AITableContentsBox,
  AITableTitlBox,
  SECONDARY,
} from "../../../styles/theme";
import {
  ActionItemDetailInfo,
  BasicInfo,
  ManagerInfo,
} from "../../../system/types/ActionItem";
import { CustomText } from "../../../styles/CustomText";
import { Gray700 } from "../../../styles/Colors";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import { useHistory } from "react-router-dom";
import { AIRoutes } from "../../../system/types/type";
import { useState } from "react";
import ActionEdit from "../ActionEdit";
import { InitActionItemInfo } from "../../../system/types/initObject";
import uuid from "react-uuid";

interface AIDetailChildProps {
  data: ActionItemDetailInfo[];
  refreshData: () => void;
  parentId: number;
}

function AIDetailChild({ data, refreshData, parentId }: AIDetailChildProps) {
  const history = useHistory();
  const moveToDetailPage = (id: number) => {
    history.push({
      pathname: `${AIRoutes.root}/detail/${id}`,
    });
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <AITableCardBox mt="10px">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <CustomText type="subtitle" bold600 color={Gray700}>
          하위 A/I(Action Item)
        </CustomText>
        <Chip
          variant="outlined"
          color="success"
          size="small"
          label="추가"
          icon={<AddIcon />}
          style={{ cursor: "pointer", marginRight: "5px" }}
          onClick={() => setOpen(true)}
        />
      </Grid>
      <AITableContentsBox container item xs={12}>
        {data && data.length > 0 ? (
          <Grid container item xs={12}>
            <AITableTitlBox container item xs={12} alignItems="center">
              <Grid container justifyContent="center" item xs={1}>
                구분
              </Grid>
              <Grid container justifyContent="center" item xs={5}>
                액션 아이템
              </Grid>
              <Grid container justifyContent="center" item xs={3}>
                담당자
              </Grid>

              <Grid container justifyContent="center" item xs={1}>
                목표 완료일
              </Grid>

              <Grid container justifyContent="center" item xs={1}>
                상태
              </Grid>
              <Grid container justifyContent="center" item xs={1}></Grid>

              <Grid container item xs={1}></Grid>
            </AITableTitlBox>
            {data.map((child: ActionItemDetailInfo, index: number) => (
              <AILastContentBox
                container
                key={index}
                padding="10px 0px"
                alignItems="center"
                onClick={() => {
                  moveToDetailPage(child.id);
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid container justifyContent="center" item xs={1}>
                  <CustomText type="body" noWrap cursorPointer>
                    {child.categories?.length > 0 &&
                      child.categories
                        .map((dt: BasicInfo) => dt.name)
                        .join(", ")}
                  </CustomText>
                </Grid>
                <Grid container justifyContent="center" item xs={5}>
                  <CustomText type="body" noWrap cursorPointer>
                    {child?.title}
                  </CustomText>
                </Grid>
                <Grid container justifyContent="center" item xs={3}>
                  <CustomText type="body" noWrap cursorPointer>
                    {child.managers
                      .filter((x: ManagerInfo) => x.isPrimaryManager)
                      .map((dt, index) => {
                        return (
                          <Chip
                            key={uuid()}
                            style={{
                              backgroundColor: "#1b4c97",
                              color: "white",
                              marginRight: "2px",
                            }}
                            label={"정) " + dt.manager.name}
                            size="small"
                          />
                        );
                      })}
                    {child.managers
                      .filter((x: ManagerInfo) => !x.isPrimaryManager)
                      .map((dt, index) => {
                        return (
                          <Chip
                            key={uuid()}
                            style={{
                              backgroundColor: SECONDARY,
                              color: "white",
                              marginRight: "2px",
                            }}
                            label={"부) " + dt.manager.name}
                            size="small"
                          />
                        );
                      })}
                  </CustomText>
                </Grid>

                <Grid container justifyContent="center" item xs={1}>
                  <CustomText type="body" noWrap cursorPointer>
                    {moment(child?.dueDate).format("YYYY-MM-DD")}
                  </CustomText>
                </Grid>
                <Grid container justifyContent="center" item xs={1}>
                  <CustomText type="body" noWrap cursorPointer>
                    {child.status}
                  </CustomText>
                </Grid>
                <Grid container item xs={1} justifyContent="right">
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label="이동"
                    icon={<NextPlanIcon />}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                  />
                </Grid>
              </AILastContentBox>
            ))}
          </Grid>
        ) : (
          <Grid container item xs={12} justifyContent="center" margin="20px">
            하위 A/I(Action Item)이 없습니다.
          </Grid>
        )}
      </AITableContentsBox>
      <ActionEdit
        refreshData={refreshData}
        open={open}
        handleClose={handleClose}
        previousData={{
          ...InitActionItemInfo,
          parentId: parentId,
        }}
        type="생성"
      />
    </AITableCardBox>
  );
}

export default AIDetailChild;
