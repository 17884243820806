import client from "../ApiService";
import HttpClient from "../http-client";
import { WorkUser } from "../types/type";

const baseUri: string = "/user";

class _UserApi {
  private client: HttpClient;
  private insamansaClient: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
    this.insamansaClient = new HttpClient("https://insamansa2.weareinbody.com/api");
  }

  /**
   * @description 사용자 로그인
   * @returns 사용자 로그인 정보
   */
  Login() {
    return this.client.get("/user/login");
  }

  /**
   *
   * @returns
   */
  //TODO: 사용유무 동작 확인 필요
  Logout() {
    return this.client.get("login/logout");
  }

  GetUsers(userName: string) {
    return this.client.get<WorkUser[]>(baseUri + "/list", { params: { userName } });
  }
  /**
   *
   * @returns ????
   */
  //TODO: 레거시 삭제 여부 판단 할 것
  InitUserInfo() {
    return this.client.post("login/renewal");
  }
}

const UserApi = new _UserApi(client);
export default UserApi;
