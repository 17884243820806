import { Grid } from "@mui/material";
import {
  Filter,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import DevelopNoteApi from "../../../system/api/DevelopNoteApi";
import { DevelopNoteList } from "../../../system/types/DevelopNote";
import { DevelopNoteRoutes, Ilocation } from "../../../system/types/type";

interface DevelopTableProps {
  parent: Ilocation;
  admin: boolean;
}

function DevelopTable({ parent, admin }: DevelopTableProps) {
  const pageSettings: PageSettingsModel = { pageSize: 3 };
  const history = useHistory();
  const [data, setData] = useState<DevelopNoteList[]>([]);

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      history.push({
        pathname: `${DevelopNoteRoutes.root}/detail/${selectedrecords[0].id}`,
        state: parent,
      });
    }
  };

  useEffect(() => {
    DevelopNoteApi.getDevelopNoteList(parent.parentId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [parent.parentId]);

  const onWriteDevelopNote = () => {
    history.push({
      pathname: `${DevelopNoteRoutes.root}/edit`,
      state: parent,
    });
  };

  return (
    <Grid container>
      {!admin && (
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <CustomButton onClick={onWriteDevelopNote} iconCss="e-icons e-plus">
            개발노트 작성
          </CustomButton>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="createdAt"
              headerText="작성일자"
              textAlign="Center"
              width="200"
            />
            <ColumnDirective
              field="title"
              headerText="개발주제"
              headerTextAlign="Center"
              width="300"
            />
            <ColumnDirective
              field="type"
              headerText="종류"
              textAlign="Center"
              width="200"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default DevelopTable;
