import { Chip, Grid, styled, Tooltip } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  TreeGridComponent,
  Page,
  Filter,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-treegrid";
import { useCallback, useRef } from "react";
import {
  ActionItemDetailInfo,
  BasicInfo,
  ManagerInfo,
} from "../../system/types/ActionItem";
import { AIRoutes } from "../../system/types/type";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ACTIONITEM_STATUS_ALL } from "../../system/Constants";
import {
  BaseBlack,
  ErrorDefault,
  Gray25,
  Green500,
  SuccessDefault,
} from "../../styles/Colors";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import { SECONDARY } from "../../styles/theme";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";

interface ActionItemTableProps {
  actionItemList: ActionItemDetailInfo[];
}

function ActionItemTable({ actionItemList }: ActionItemTableProps) {
  const pageOptions = { pageSize: 10 };
  const history = useHistory();

  const rowSelected = (props: any) => {
    history.push({
      pathname: `${AIRoutes.root}/detail/${props.data.id}`,
    });
  };

  const managerTemplate = useCallback((props: any): any => {
    const primaryManagerList: ManagerInfo[] = props.managers.filter(
      (x: ManagerInfo) => x.isPrimaryManager
    );
    const managerList: ManagerInfo[] = props.managers.filter(
      (x: ManagerInfo) => !x.isPrimaryManager
    );

    return (
      <DataGridContent container item xs={12}>
        <DataGridContent container item xs={12}>
          {primaryManagerList.map((dt, index) => {
            return (
              <Tooltip
                title={dt.manager.organizationName}
                key={uuid()}
                placement="left"
              >
                <Chip
                  style={{
                    backgroundColor: "#1b4c97",
                    color: "white",
                    margin: "1px 0px",
                  }}
                  label={"정) " + dt.manager.name}
                  size="small"
                />
              </Tooltip>
            );
          })}
        </DataGridContent>
        <DataGridContent container item xs={12}>
          {managerList.map((dt, index) => {
            return (
              <Tooltip
                title={dt.manager.organizationName}
                key={uuid()}
                placement="left"
              >
                <Chip
                  style={{
                    backgroundColor: SECONDARY,
                    color: "white",
                    margin: "1px 0px",
                  }}
                  label={"부) " + dt.manager.name}
                  size="small"
                />
              </Tooltip>
            );
          })}
        </DataGridContent>
      </DataGridContent>
    );
  }, []);
  const createdAtTemplate = useCallback((props: any): any => {
    return (
      <DataGridContent container item xs={12}>
        {moment(props.createdAt).format("YYYY-MM-DD")}
      </DataGridContent>
    );
  }, []);
  const organizationTemplate = useCallback((props: any): any => {
    return (
      <DataGridContent container item xs={12}>
        {props?.organizationUnit?.name}
      </DataGridContent>
    );
  }, []);
  const categoryTemplate = useCallback((props: any): any => {
    return (
      <DataGridContent container item xs={12}>
        {props.categories?.length > 0 &&
          props.categories.map((dt: BasicInfo) => dt.name).join(", ")}
      </DataGridContent>
    );
  }, []);
  const titleTemplate = useCallback((props: any): any => {
    return <span style={{ fontSize: "14px" }}>{props.title}</span>;
  }, []);
  const dueDateTemplate = useCallback((props: any): any => {
    return (
      <DataGridContent container item xs={12}>
        {moment(props.dueDate).format("YYYY-MM-DD")}
      </DataGridContent>
    );
  }, []);

  const statusRef = useRef<any>([]);
  const onChangeStatus = (id: number, e: any, idx: number) => {
    Swal.fire({
      title: `해당 액션아이템의 진행상태를 \n ${e.value}(으)로 변경하시겠습니까?`,
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: SuccessDefault,
      cancelButtonColor: ErrorDefault,
    }).then((result) => {
      if (result.isConfirmed) {
        ActionItemsApi.updateActionItemStatus(id, e.value)
          .then(() => {
            alert("진행상태가 변경되었습니다.");
          })

          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            statusRef.current[idx].value = null;
          });
      }
    });
  };
  const statusTemplate = useCallback((props: any): any => {
    let AsmtStatus = props.status;
    let AsmtSubStatus = props.subStatus;
    return (
      <DataGridContent>
        <DropDownListComponent
          ref={(scope) => {
            statusRef.current[props.index] = scope;
          }}
          id="ddlelement"
          dataSource={ACTIONITEM_STATUS_ALL}
          placeholder={
            props.subStatus ? `${AsmtStatus}-${AsmtSubStatus}` : AsmtStatus
          }
          onChange={(e: any) => onChangeStatus(props.id, e, props.index)}
        />
      </DataGridContent>
    );
  }, []);

  const calculateCompletionRate = (
    actionItem: ActionItemDetailInfo
  ): number => {
    const children = actionItem.children;

    // children이 ActionItemDetailInfo[] 타입인지 확인
    if (
      !Array.isArray(children) ||
      children.some(
        (child) => typeof child !== "object" || !("status" in child)
      )
    ) {
      console.error("children 배열에 잘못된 데이터가 포함되어 있습니다.");
      return 0; // 잘못된 데이터인 경우 비율 0 반환
    }

    // children이 빈 배열인 경우
    if (children.length === 0) {
      return 0; // 자식이 없는 경우 비율은 0
    }

    // '완료' 상태인 자식들의 수를 계산
    const completedCount = children.filter(
      (child) => child.status === "완료"
    ).length;

    // 완료 비율 계산
    const completionRate = (completedCount / children.length) * 100;

    return completionRate;
  };

  const progressTemplate = useCallback((props: any): any => {
    const completionRate = calculateCompletionRate(props);
    return (
      <DataGridContent container item xs={12}>
        {props?.children.length > 0 ? (
          <ProgressBarComponent
            id="linear"
            type="Linear"
            trackThickness={20}
            progressThickness={20}
            showProgressValue={true}
            progressColor={Green500}
            trackColor={Gray25}
            value={completionRate}
            cornerRadius="Round"
            labelStyle={{ color: BaseBlack, size: "14px !important" }}
            textRender={(args) => {
              args.text =
                props.children.length > 0
                  ? completionRate.toFixed(2) + " %"
                  : "";
            }}
            animation={{
              enable: false,
            }}
            style={{ marginLeft: "-10px" }}
          />
        ) : (
          ""
        )}
      </DataGridContent>
    );
  }, []);
  const onRowDataBound = (args: any) => {
    if (!args.data.parentItem) {
      // 최상위 아이템인지 확인
      args.row.classList.add("top-level-item"); // 클래스 추가
    }
  };

  return (
    <Grid container item xs={12} mt="10px">
      <TreeGridComponent
        dataSource={actionItemList}
        treeColumnIndex={0}
        childMapping="children"
        allowFiltering={true}
        allowSorting={true}
        allowPaging={true}
        pageSettings={pageOptions}
        enableCollapseAll={true}
        rowDataBound={onRowDataBound}
        rowSelected={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="title"
            headerText="액션 아이템"
            width="300"
            template={titleTemplate}
          />

          <ColumnDirective
            field="category.name"
            headerText="구분"
            width="100"
            template={categoryTemplate}
          />

          <ColumnDirective
            field="managers"
            headerText="담당자"
            width="100"
            template={managerTemplate}
          />
          <ColumnDirective
            field="dueDate"
            headerText="목표 완료일"
            width="100"
            template={dueDateTemplate}
          />
          <ColumnDirective
            headerText="진행률"
            width="100"
            template={progressTemplate}
          />
          <ColumnDirective
            field="status"
            headerText="상태"
            width="100"
            template={statusTemplate}
          />
          <ColumnDirective
            field="organizationUnit"
            headerText="작성부서"
            width="100"
            template={organizationTemplate}
          />
          <ColumnDirective
            field="createdAt"
            headerText="발생일"
            width="100"
            template={createdAtTemplate}
          />
        </ColumnsDirective>
        <Inject services={[Page, Filter, Sort]} />
      </TreeGridComponent>
    </Grid>
  );
}

export default ActionItemTable;

const DataGridContent = styled(Grid)({
  fontSize: "14px",
  cursor: "pointer",
});
