import { Chip, Grid, styled } from "@mui/material";
import { ActionItemDetailInfo } from "../../system/types/ActionItem";
import AIDetailInfo from "./ActionItemDetails/AIDetailInfo";
import AIDetailChild from "./ActionItemDetails/AIDetailChild";
import AIDetailAttachment from "./ActionItemDetails/AIDetailAttachment";
import { CustomButton } from "../../styles/theme";
import { AIRoutes } from "../../system/types/type";
import { useHistory } from "react-router-dom";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import { CustomText } from "../../styles/CustomText";
import { ErrorDefault, Gray700, SuccessDefault } from "../../styles/Colors";
import Swal from "sweetalert2";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import AlertModal from "../Common/AlertModal";
import { ErrorHandler } from "../../system/ApiService";
import AIDetailComment from "./ActionItemDetails/AIDetailComment";
import { useEffect } from "react";
interface ActionItemDetailsProps {
  data: ActionItemDetailInfo;
  refreshData: () => void;
}

function ActionItemDetails({ data, refreshData }: ActionItemDetailsProps) {
  const leftBtn = { marginLeft: "10px" };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var submitcheck = false;
  function onDelete() {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 A/I(Action Item)이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: SuccessDefault,
        cancelButtonColor: ErrorDefault,
      }).then((result) => {
        if (result.isConfirmed && data.id) {
          ActionItemsApi.deleteActionItem(data.id)
            .then(() => {
              AlertModal(
                "success",
                "선택하신 A/I(Action Item)이 삭제되었습니다."
              );
              history.push({
                pathname: `${AIRoutes.root}`,
              });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  }
  const history = useHistory();
  const moveToDetailPage = () => {
    history.push({
      pathname: `${AIRoutes.root}/detail/${data.parent?.id}`,
    });
  };

  return (
    <Grid container item xs={12}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        {data.parent?.id ? (
          <CardSmallBox
            container
            item
            xs={12}
            sm={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item style={{ width: `calc(100% - 80px)` }}>
              <CustomText
                type="subtitle"
                bold600
                color={Gray700}
                margin="0px 20px 0px 5px"
              >
                상위 A/I(Action Item)
              </CustomText>{" "}
              {data.parent?.title}
            </Grid>
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="이동"
              icon={<NextPlanIcon />}
              onClick={moveToDetailPage}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
          </CardSmallBox>
        ) : (
          <Grid item></Grid>
        )}

        <CustomButton
          style={leftBtn}
          iconCss="e-icons e-trash"
          onClick={onDelete}
        >
          삭제
        </CustomButton>
      </Grid>
      <AIDetailInfo data={data} refreshData={refreshData} />
      <AIDetailChild
        data={data.children}
        refreshData={refreshData}
        parentId={data.id}
      />
      <AIDetailAttachment
        data={data.attachmentFiles}
        id={data.id}
        refreshData={refreshData}
      />
      <AIDetailComment data={data} refreshData={refreshData} />
    </Grid>
  );
}

export default ActionItemDetails;

const CardSmallBox = styled(Grid)({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  padding: "5px",
});
