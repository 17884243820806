import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ChipIsResignation,
  StatisticsTitle,
  StyledBodyNoPadding,
  StyledHead1,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import StatisticsApi from "../../../system/api/StatisticsApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AggTeamDetail } from "../../../system/types/Statistics";

interface NonPctDetailProps {
  year: string;
  department: string | null;
  team: string | null;
  participationType: string | null;
}

function NonPctDetail({
  year,
  department,
  team,
  participationType,
}: NonPctDetailProps) {
  const [data, setData] = useState<AggTeamDetail[]>([]);
  const Loading = useLoadingDispatch();
  useEffect(() => {
    Loading({ type: "LOADING" });
    department || team
      ? StatisticsApi.GetTeamDetailStatistics({
          year: year,
          department: department,
          team: team,
          aggregationStatus: "미참여",
          participationType: "전체",
        })
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          })
      : StatisticsApi.GetTeamDetailStatistics({
          year: year,
          department: "",
          team: "",
          aggregationStatus: "미참여",
          participationType: "전체",
        })
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
  }, [year, department, team, Loading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <StatisticsTitle>
          {team ? (
            <>{team} 과제 미참여 인원 현황</>
          ) : (
            <>
              {department ? (
                <>{department} 과제 미참여 인원 현황</>
              ) : (
                "전사 과제 미참여 인원 현황"
              )}
            </>
          )}
        </StatisticsTitle>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">파트</StyledHead1>
                <StyledHead1 align="center">팀</StyledHead1>
                <StyledHead1 align="center">이름</StyledHead1>
                <StyledHead1 align="center">직급</StyledHead1>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <StyledBodyNoPadding align="center">
                        {item.department}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding align="center">
                        {item.team}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding align="center">
                        {item.name}
                        {item.isResignation && (
                          <ChipIsResignation>퇴사</ChipIsResignation>
                        )}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding align="center">
                        {item.jobPosition}
                      </StyledBodyNoPadding>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default NonPctDetail;
