//react
import React, { Fragment, ReactElement, useRef, useState } from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import "./App.css";
import Routes from "./route/Routes";
import UserRoute from "./route/UserRoute";

// recoil
import { RecoilRoot } from "recoil";

//Azure
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./azure/authConfig";
import LoginRedirect from "./azure/LoginRedirect";

//context
import { LoadingContextProvider } from "./system/context/LoadingContext";
import {
  UserContextProvider,
  useUserDispatch,
} from "./system/context/UserContext";

//api
import UserApi from "./system/api/UserApi";

//object
import { AuthorityUser } from "./system/types/interface";
import { CommonRoutes } from "./system/types/type";

//lib
import {
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";

//var
let dupCheck = true;

function App() {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoginRedirect></LoginRedirect>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <UserContextProvider>
          <LoadingContextProvider>
            <RecoilRoot>
              <UserWrapper></UserWrapper>
            </RecoilRoot>
          </LoadingContextProvider>
        </UserContextProvider>
      </AuthenticatedTemplate>
    </>
  );
}

function UserWrapper(): ReactElement {
  const dispatch = useUserDispatch();
  const { instance, accounts } = useMsal();
  const [isInit, setIsInit] = useState(false);
  const userRef = useRef<AuthorityUser | undefined>();
  const history = useHistory();

  React.useEffect(() => {
    // sso-login
    const ssoLogin = async () => {
      const idToken = await msalLogin();
      if (idToken === null || idToken === undefined) {
        return;
      }

      try {
        userRef.current = await UserApi.Login().then((response) => {
          return response.data;
        });

        // 유저 정보
        if (userRef.current !== undefined) {
          dispatch({ type: "LOGIN", item: userRef.current });
        }

        //메인페이지로 이동
        history.push("/");
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 401) {
            msalLogin();
          } else {
            alert("등록되지 않은 이메일로 접속이 불가능합니다.");
            window.location.replace("https://weareinbody.com");
          }
        }
      } finally {
        setIsInit(true);
      }
    };

    const msalLogin = async () => {
      let response;
      try {
        // lookinbody.com 메일 있는 경우 inbody.com 계정 찾는 부분
        const account = accounts.find((x) =>
          x.username.includes("@inbody.com")
        );
        if (account?.username) {
          try {
            response = await instance.ssoSilent({
              scopes: loginRequest.scopes,
              sid: account?.idTokenClaims?.sid,
            });
          } catch (err) {
            console.log("SSO silent login fail!");
            try {
              response = await instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: account,
              });
            } catch (error) {
              console.log("Token silent login fail!");
              if (err instanceof InteractionRequiredAuthError) {
                try {
                  response = await instance.acquireTokenPopup({
                    ...loginRequest,
                  });
                } catch (err) {
                  console.log(err);
                  if (err instanceof BrowserAuthError) {
                    if (err.message.includes("user_cancelled")) {
                      alert(
                        "로그인을 취소하셨습니다.\n정상적인 사이트 이용이 불가능합니다."
                      );
                      window.location.replace("https://weareinbody.com");
                    } else if (err.message.includes("popup_window_error"))
                      alert(
                        "팝업 차단 기능이 설정되어 있습니다.\n\n팝업 차단을 '해제'하고 '새로고침' 버튼을 눌러주세요."
                      );
                  } else {
                    alert("로그인에 실패했습니다. 관리자에게 문의해주세요.");
                  }
                }
              }
            }
          }
        }
        return response?.idToken;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    if (dupCheck) {
      ssoLogin();
      dupCheck = !dupCheck;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, dispatch, instance]);

  if (isInit) {
    return (
      <Router>
        <Route path={CommonRoutes.login}></Route>
        <UserRoute Component={Routes}></UserRoute>
      </Router>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

export default App;
