import HttpClient from "../http-client";
import client from "../ApiService";
import { MeetingNoteDetail } from "../types/MeetingNote";

const baseUri: string = "/article/meeting";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _MeetingNoteApi extends _BasicApi {
  getMeetingNoteList(parentId: number) {
    return this.client.get(`${baseUri}/all/${parentId}`);
  }
  createMeetingNoteList(meetingNote: MeetingNoteDetail) {
    return this.client.post(`${baseUri}/create`, meetingNote);
  }
  getMeetingNoteDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  updateMeetingNote(meetingNote: MeetingNoteDetail) {
    return this.client.put(`${baseUri}/update`, meetingNote);
  }
  deleteMeetingNote(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
}

const MeetingNoteApi = new _MeetingNoteApi(client);
export default MeetingNoteApi;
