import DailyDetail from "../../components/Daily/DailyDetail";

function DailyDetailMain() {
  return (
    <>
      <DailyDetail />
    </>
  );
}

export default DailyDetailMain;
